function InstagramIcon() {
  return (
    <svg className='social-media-icon' width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='#1C1C21' />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='url(#paint0_linear_1104_509)' />
      <path
        d='M21.0022 15.8723C18.1645 15.8723 15.8756 18.1617 15.8756 21C15.8756 23.8383 18.1645 26.1277 21.0022 26.1277C23.8399 26.1277 26.1288 23.8383 26.1288 21C26.1288 18.1617 23.8399 15.8723 21.0022 15.8723ZM21.0022 24.3337C19.1684 24.3337 17.6693 22.8387 17.6693 21C17.6693 19.1613 19.164 17.6663 21.0022 17.6663C22.8405 17.6663 24.3352 19.1613 24.3352 21C24.3352 22.8387 22.836 24.3337 21.0022 24.3337ZM27.5343 15.6625C27.5343 16.3275 26.9989 16.8585 26.3385 16.8585C25.6737 16.8585 25.1428 16.323 25.1428 15.6625C25.1428 15.002 25.6782 14.4665 26.3385 14.4665C26.9989 14.4665 27.5343 15.002 27.5343 15.6625ZM30.9297 16.8764C30.8539 15.2742 30.488 13.8551 29.3146 12.6858C28.1456 11.5166 26.7267 11.1506 25.1249 11.0703C23.4741 10.9766 18.5259 10.9766 16.8751 11.0703C15.2777 11.1462 13.8589 11.5121 12.6854 12.6814C11.512 13.8506 11.1506 15.2698 11.0703 16.8719C10.9766 18.5232 10.9766 23.4724 11.0703 25.1236C11.1461 26.7258 11.512 28.1449 12.6854 29.3142C13.8589 30.4834 15.2733 30.8494 16.8751 30.9297C18.5259 31.0234 23.4741 31.0234 25.1249 30.9297C26.7267 30.8538 28.1456 30.4879 29.3146 29.3142C30.4835 28.1449 30.8494 26.7258 30.9297 25.1236C31.0234 23.4724 31.0234 18.5276 30.9297 16.8764ZM28.797 26.8953C28.449 27.7701 27.7752 28.4439 26.8963 28.7965C25.58 29.3186 22.4568 29.1981 21.0022 29.1981C19.5477 29.1981 16.42 29.3142 15.1082 28.7965C14.2337 28.4484 13.56 27.7745 13.2075 26.8953C12.6854 25.5788 12.8059 22.4549 12.8059 21C12.8059 19.5451 12.6899 16.4167 13.2075 15.1047C13.5555 14.2299 14.2292 13.5561 15.1082 13.2035C16.4244 12.6814 19.5477 12.8019 21.0022 12.8019C22.4568 12.8019 25.5845 12.6858 26.8963 13.2035C27.7708 13.5516 28.4445 14.2255 28.797 15.1047C29.319 16.4212 29.1985 19.5451 29.1985 21C29.1985 22.4549 29.319 25.5833 28.797 26.8953Z'
        fill='white'
      />
    </svg>
  )
}

export default InstagramIcon
