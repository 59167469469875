import classes from './PrivacyPolicy.module.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPrivacyPolicy } from '../../../store/client/privacyPolicy'

function PrivacyPolicy() {
  useEffect(() => {
    dispatch(getPrivacyPolicy())
  }, [])
  const dispatch = useDispatch()
  const privacyPolicy = useSelector((state) => state.clientPrivacyPolicy.privacyPolicy.details)

  return (
    <>
      <div className={classes['privacy-policy-container']}>
        <h1>PrivacyPolicy</h1>

        <div className={classes['privacy-policy-details']} dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
      </div>
    </>
  )
}

export default PrivacyPolicy
