/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { RiTeamLine } from 'react-icons/ri'
import { GiKnightBanner } from 'react-icons/gi'
import { MdReviews, MdPermContactCalendar } from 'react-icons/md'
import { AiOutlineProject, AiTwotoneCustomerService, AiTwotonePieChart, AiOutlineBell } from 'react-icons/ai'
import { MdPermMedia } from 'react-icons/md'
import { FcSettings } from 'react-icons/fc'
import { SiAboutdotme } from 'react-icons/si'
import { BiUserCircle } from 'react-icons/bi'
import AdminRoutes from '../../routes/cmsRoutes/CmsRoutes'
import PresentWorkingPanel from '../../components/PresentWorkingPanel'
import firstLetterCaps from '../../utils/firstLetterCaps'

const { Content, Footer, Sider } = Layout

function getItem(label, icon, children) {
  return {
    key: uuidv4(),
    icon,
    children,
    label
  }
}
const items = [
  getItem(
    <Link to='/admin'>Dashboard</Link>,
    <Link to='/admin'>
      <AiTwotonePieChart size={25} />
    </Link>
  ),
  getItem('Banner Management', <GiKnightBanner size={25} />, [
    getItem(<Link to='viewBanner'>{firstLetterCaps('View Banners')}</Link>),
    getItem(<Link to='addBanner'>{firstLetterCaps('Add Banner')}</Link>)
  ]),
  getItem('Team Management', <RiTeamLine size={25} />, [
    getItem(<Link to='/admin/viewTeam'>{firstLetterCaps('View Team')}</Link>),
    getItem(<Link to='/admin/addTeam'>{firstLetterCaps('Add team')}</Link>)
  ]),
  getItem('Testimonial Management', <MdReviews size={25} />, [
    getItem(<Link to='/admin/viewTestimonial'>{firstLetterCaps('View Testimonial')}</Link>),
    getItem(<Link to='/admin/addTestimonial'>{firstLetterCaps('Add testimonial')}</Link>)
  ]),
  getItem('Project Management', <AiOutlineProject size={25} />, [
    getItem(<Link to='/admin/viewProject'>{firstLetterCaps('View Project')}</Link>),
    getItem(<Link to='/admin/addProject'>{firstLetterCaps('Add project')}</Link>)
  ]),
  getItem(<Link to='/admin/contact'>{firstLetterCaps('Inquiry')}</Link>, <MdPermContactCalendar size={25} />),
  getItem(
    <Link to='/admin/media'>{firstLetterCaps('Media Management')}</Link>,
    <Link to='/admin/media'>
      <MdPermMedia size={25} />
    </Link>
  ),
  getItem(
    <Link to='/admin/servicePanel'>{firstLetterCaps('Service Management')}</Link>,
    <Link to='/admin/servicePanel'>
      <AiTwotoneCustomerService size={25} />
    </Link>
  ),
  getItem(
    <Link to='/admin/aboutManagement'>{firstLetterCaps('About Management')}</Link>,
    <Link to='/admin/aboutManagement'>
      <SiAboutdotme size={25} />
    </Link>
  ),

  // getItem(<Link to="/admin/siteSetting">{firstLetterCaps("Site Setting")}</Link>, <Link to="/admin/siteSetting"><FcSettings size={25} /></Link>),
  getItem('Site Settings', <FcSettings size={25} />, [
    getItem(<Link to='sitemanagement'>{firstLetterCaps('Site Management')}</Link>),
    getItem(<Link to='termsandconditions'>{firstLetterCaps('Terms and Conditions')}</Link>),
    getItem(<Link to='privacypolicy'>{firstLetterCaps('Privacy Policy')}</Link>)
  ]),
  getItem(
    <Link to='/admin/user'>{firstLetterCaps('User')}</Link>,
    <Link to='/admin/notice-management'>
      <BiUserCircle size={25} />
    </Link>
  )
]

const CmsPanel = () => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ height: '100vh', overflowY: 'auto' }}
        width={300}
      >
        <div
          className='logo'
          style={{
            color: 'white'
          }}
        ></div>
        <Menu
          theme='dark'
          defaultSelectedKeys={['1']}
          mode='inline'
          items={items}
          style={{ height: '100vh', overflowY: 'auto', width: '100%' }}
        />
      </Sider>

      <Layout className='site-layout' style={{}}>
        <Content
          style={{
            margin: '0 16px',
            height: '95vh',
            overflowY: 'auto'
          }}
        >
          <PresentWorkingPanel />
          <div
            className='site-layout-background'
            style={{
              padding: '0 24px 14px 24px',
              minHeight: '80vh'
            }}
          >
            <AdminRoutes />
          </div>
        </Content>
        <Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            maxHeight: '1rem',
            height: '5vh'
          }}
        >
          ©{new Date().getFullYear()} Created by Mervix Technologies
        </Footer>
      </Layout>
    </Layout>
  )
}
export default CmsPanel
