/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const getAbout = createAsyncThunk('about/getAbout', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/about')
    if (response.data.data) {
      params.fillForm(response.data.data)
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all about!')
  }
})

export const postAbout = createAsyncThunk('about/postAbout', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/about', params.formData)
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add about!')
  }
})

const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    about: []
  },
  extraReducers: {
    [getAbout.fulfilled]: (state, { payload }) => {
      state.about = payload
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.'
      })
    },
    [getAbout.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Cannot fetch abouts!'
      })
    },
    [getAbout.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postAbout.fulfilled]: (state, action) => {
      state.about = action.payload
      notification('success', {
        message: 'Success.',
        description: 'About saved successfully.'
      })
    },
    [postAbout.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! '
      })
    }
  }
})
export default aboutSlice
