import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getAllTeam = createAsyncThunk('team/getClientAllTeam', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/team')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all team!')
  }
})

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    status: 'PASSIVE',
    team: []
  },
  extraReducers: {
    [getAllTeam.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.team = payload
    },
    [getAllTeam.rejected]: (state) => {
      state.status = 'REJECTED'
    },
    [getAllTeam.pending]: (state) => {
      state.status = 'PENDING'
    }
  }
})
export default teamSlice
