import classes from './Milestone.module.css'
import PropTypes from 'prop-types'

const MilestoneCard = ({ number, description, className }) => {
  return (
    <div className={`${classes.card} ${className ? className : ''}`}>
      <h1 className={`${classes.number} number`}>{number}</h1>
      <div className={`${classes.description} smaill-text`}>{description}</div>
    </div>
  )
}

export default MilestoneCard

MilestoneCard.propTypes = {
  number: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string
}
