import { createSlice } from '@reduxjs/toolkit'

const mediaSelectSlice = createSlice({
  name: 'mediaSelect',
  initialState: {
    list: []
  },
  reducers: {
    addSelectedItem(state, action) {
      const { customid, mode, media } = action.payload
      const list = state[customid]?.list || []

      switch (mode) {
        case 'single':
          state[customid] = { list: [media] }
          break
        case 'multiple':
          // if (!list.some((item) => item.id === media.id)) {
          //   state[customid] = { list: [...list, media] }
          // }
          // Check if the media item is already in the list by comparing IDs
          // eslint-disable-next-line no-case-declarations
          const exists = list.some((item) => item._id === media._id)
          // If the media item is not already in the list, add it
          if (!exists) {
            state[customid] = { list: [...list, media] }
          }
          break
        default:
          break
      }
    },
    deleteSelectedItem(state, action) {
      const { customid, mode, media } = action.payload
      const list = state[customid]?.list || []
      let index = -1
      switch (mode) {
        case 'single':
          state[customid] = { list: [] }
          break
        case 'multiple':
          index = list.findIndex((item) => item._id === media._id)
          if (index >= 0) {
            const updatedList = [...list.slice(0, index), ...list.slice(index + 1)]
            state[customid] = { list: updatedList }
          }
          break
        default:
          break
      }
    },
    initializeList(state, action) {
      const { customid } = action.payload
      state[customid] = { list: [] }
    },
    fillSelectedMedia(state, action) {
      const { customid, data } = action.payload
      if (customid === 'project') {
        state[customid] = { list: data || '' }
      } else {
        state[customid] = { list: [data] }
      }
    }
  }
})

// export const {
//   addSelectedItem,
//   deleteSelectedItem,
//   initializeList,
//   fillSelectedMedia,
// } = mediaSelectSlice.actions;

export default mediaSelectSlice
