import React from 'react'
import { Link } from 'react-router-dom'
import NotFound from '../assets/404.svg'
const PageNotFound = () => {
  return (
    <div>
      <h3>404 - PAGE NOT FOUND</h3>
      {/* <NotFound/> */}
      <div style={{ width: '100%' }}>
        <img src={NotFound} alt='not found' style={{ maxHeight: '60vh', width: '100%' }} />
        <h1>
          <Link to={'/'} style={{ padding: '20px' }}>
            Go back
          </Link>
        </h1>
      </div>
    </div>
  )
}
export default PageNotFound
