import Top from '../../../components/Top'
import ServicePreview from '../../../components/Services'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllService } from '../../../store/client/serviceSlice'
import Seo from '../../../utils/seo'

const Services = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllService())
  }, [])
  const services = useSelector((state) => state.clientService.list)
  return (
    <>
      <Seo
        title='Revenge Bhatta: Services'
        description='We offers expert photography, videography, and production management services, ensuring every moment is immortalized with creativity and finesse.'
      />
      <Top title='services' />
      <ServicePreview services={services} />
    </>
  )
}

export default Services
