import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from '../../components/Layout/User'
import Landing from '../../pages/client/landing/Landing'
import AboutMe from '../../pages/client/about/About'
import Services from '../../pages/client/services/Services'
import Projects from '../../pages/client/projects/Projects'
import Contact from '../../pages/client/contact/Contact'
import ProjectDetail from '../../pages/client/projectDetail/ProjectDetail'
import ServiceDetail from '../../pages/client/serviceDetail/ServiceDetail'
import PageNotFound from '../../pages/404'
import TermsAndConditions from '../../pages/client/termsAndConditions/TermsAndConditions'
import PrivacyPolicy from '../../pages/client/privacyPolicy/PrivacyPolicy'
// import CookieBar from '../../components/CookieBar/cookieBar'
const ClientRoutes = () => {
  return (
    <div
      style={{
        maxWidth: '1900px',
        margin: 'auto'
      }}
    >
      <Layout>
        <Suspense fallback={<h3>Loading...</h3>}>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about-me' element={<AboutMe />} />
            <Route path='/services' element={<Services />} />
            <Route path='/services/:serviceType' element={<ServiceDetail />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/:slug' element={<ProjectDetail />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            {/* Route for unmatched paths */}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </Layout>
      {/* <CookieBar /> */}
    </div>
  )
}
export default ClientRoutes
