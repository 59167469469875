/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'
import { cmsTestimonialActions } from '../index'
export const postTestimonial = createAsyncThunk('testimonial/postTestimonial', async (params, { rejeceWithValue, dispatch }) => {
  try {
    const response = await axios.post('/admin/testimonial/', params.formData)
    // dispatch(cmsTestimonialActions.addOneTestimonialToList(response.data.team))
    params.resetForm()
    return response.data.data
  } catch (err) {
    return rejeceWithValue('Error posting testimonial!')
  }
})

export const deleteTestimonial = createAsyncThunk('testimonial/deleteTestimonial', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`/admin/testimonial/${params._id}`)
    dispatch(cmsTestimonialActions.deleteTestimonial(response.data.data))
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to delete testimonial!')
  }
})

export const getAllTestimonial = createAsyncThunk('testimonial/getAllTestimonial', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/admin/testimonial')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Falied to load testimonial.')
  }
})

export const getOneTestimonial = createAsyncThunk('testimonial/getOneTestimonial', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/testimonial/${params._id}`)
    params.fillForm(response.data.data)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed To Fetch Testimonial Info')
  }
})

export const patchTestimonial = createAsyncThunk('testimonial/putTestimonial', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch(`/admin/testimonial/${params._id}`, params.formData)
    if (params.enableSwitch) {
      params.enableSwitch()
    } else {
      params.navigate()
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to update data!')
  }
})

const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState: { list: [] },
  reducers: {
    addOneTestimonialToList(state, action) {
      state.list = [action.payload, ...state.list]
    },
    deleteTestimonial(state, action) {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
    }
  },
  extraReducers: {
    [postTestimonial.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [postTestimonial.fulfilled]: (state, action) => {
      notification('success', { message: 'Saved Successfully.' })
    },
    [postTestimonial.rejected]: (state, action) => {
      notification('error', { message: 'Error Saving Data!' })
    },
    [deleteTestimonial.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [deleteTestimonial.fulfilled]: (state, action) => {
      notification('success', { message: 'Deleted Successfully.' })
    },
    [deleteTestimonial.rejected]: (state, action) => {
      notification('error', { message: 'Error Deleteing Data!' })
    },
    [getAllTestimonial.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [getAllTestimonial.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', { message: 'Fetched Successfully.' })
    },
    [getAllTestimonial.rejected]: (state, action) => {
      notification('error', { message: 'Error Fetching Data!' })
    },
    [getOneTestimonial.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [getOneTestimonial.fulfilled]: (state, action) => {
      notification('success', { message: 'Fetched Successfully.' })
    },
    [getOneTestimonial.rejected]: (state, action) => {
      notification('error', { message: 'Error Fetching Data!' })
    },
    [patchTestimonial.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [patchTestimonial.fulfilled]: (state, action) => {
      notification('success', { message: 'Success.', description: 'Successfully updated.' })
    },
    [patchTestimonial.rejected]: (state) => {
      // eslint-disable-next-line quotes
      notification('error', { message: 'Error!', description: "Testimonial doesn't exist or validation error! " })
    }
  }
})
export default testimonialSlice
