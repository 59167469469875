/* eslint-disable quotes */
import ProjectList from '../../../components/Projects'
import TalkProject from '../../../components/TalkProject'
import Top from '../../../components/Top'
import Seo from '../../../utils/seo'

const Projects = () => {
  return (
    <>
      <Seo
        title='Revenge Bhatta: Projects'
        description={
          "Revenge Bhatta: Let's Create Memories specializes in capturing life's most cherished moments with unmatched artistry and passion."
        }
      />
      <Top title='Projects' />
      <ProjectList />
      <TalkProject />
    </>
  )
}
export default Projects
