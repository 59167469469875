/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './Projects.module.css'
import { Tabs, Pagination } from 'antd'
import ProjectPreviewCard from '../Card/ProjectPreview'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAllProject } from '../../store/client/projectSlice'
import { getAllService } from '../../store/client/serviceSlice'

const Projects = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  // State to store the currently selected tab key
  const [selectedTab, setSelectedTab] = useState('All')

  useEffect(() => {
    dispatch(getAllProject())
    dispatch(getAllService())
  }, [])
  const projects = useSelector((state) => state.clientProject.list)
  const services = useSelector((state) => state.clientService.list)
  // Extract unique categories from projects
  // eslint-disable-next-line no-undef
  const categories = ['All', ...new Set(services.map((service) => service.serviceName))]
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 9

  // Get query parameter from URL
  const pageQueryParam = searchParams.get('page')
  // Set current page based on query parameter
  const initialPage = parseInt(pageQueryParam) || 1

  // Set initial page when component mounts
  if (currentPage !== initialPage) {
    setCurrentPage(initialPage)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // const currentPageData = projects.slice(startIndex, endIndex)
  // Filter projects based on the selected tab key
  const filteredProjects =
    selectedTab === 'All' ? projects : projects.filter((project) => project.category.toLowerCase() === selectedTab.toLowerCase())

  const handlePageChange = (page) => {
    setCurrentPage(page)
    // Update query parameter in URL
    searchParams.set('page', page)
    navigate({ search: searchParams.toString() })
  }

  const onChange = (key) => {
    // Set the selected tab key
    setSelectedTab(key)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.category_tab}>
          <Tabs
            className={`${classes.tab} projects-tab`}
            defaultActiveKey='All'
            activeKey={selectedTab}
            items={categories.map((category) => ({ key: category, label: category }))}
            onChange={onChange}
            indicator={{
              size: (origin) => origin - 20,
              align: 'center'
            }}
          />
        </div>
        <div className={classes.projects}>
          {filteredProjects.length === 0 ? (
            <div style={{ textAlign: 'center' }}>No data available</div>
          ) : (
            <>
              <div className={classes.flex_container}>
                {filteredProjects.slice(startIndex, endIndex).map((item, index) => (
                  <ProjectPreviewCard
                    className='project-card'
                    key={index}
                    projectName={item?.title}
                    media={item?.featuredMediaType?.toLowerCase() === 'image' ? item.featuredImage?.images?.[1] : item?.youTubeLink}
                    projectDate={item?.projectYear}
                    slug={item?.slug}
                  />
                ))}
              </div>
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={filteredProjects.length}
                showSizeChanger={false} // Hide size changer
                showQuickJumper={false} // Hide quick jumper
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} // Show total items
                onChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default Projects
