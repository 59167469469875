import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import classes from './ProjectDetail.module.css'
import XIcon from '../../../components/Icon/X'
import FacebookIcon from '../../../components/Icon/Facebook'
import LinkedInIcon from '../../../components/Icon/LinkedIn'
import Gallery from '../../../components/Gallery'
import { getOneProject } from '../../../store/client/projectSlice'
import generateSrcSetAndSizes from '../../../utils/generateSrcSetAndSizes'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import ReactPlayer from 'react-player'
import Seo from '../../../utils/seo'

const ProjectDetail = () => {
  const { slug } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOneProject({ slug }))
  }, [])
  const project = useSelector((state) => state.clientProject.selectedProject)
  const { srcSet, sizes } = generateSrcSetAndSizes(project?.featuredImage)

  // Extract the video ID from the YouTube link
  function getYoutubeVideoId(url) {
    if (!url) return null // Handle case where url is undefined or null
    const videoIdMatch = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?vi=))([^&?]+)/)
    return videoIdMatch && videoIdMatch[1]
  }

  // Construct the thumbnail URL using the video ID
  function getYoutubeThumbnailUrl(videoId) {
    return `https://img.youtube.com/vi/${videoId}/0.jpg`
  }

  return (
    <>
      <Seo
        title={`${project?.title || 'Revenge Bhatta - Project'} | ${project?.category || ''} | ${project?.projectYear || ''}`}
        description={project?.summary}
        image={project?.featuredImage?.images?.[0].url || getYoutubeThumbnailUrl(getYoutubeVideoId(project?.youTubeLink))}
      />
      <section className={classes.container}>
        <div className={`${classes.header_flex}`}>
          <div className={`${classes.title} highlight-text`}>
            {project?.category} | {project?.projectYear}{' '}
          </div>
          <h2>{project?.title}</h2>
        </div>
        <div className={`${classes.image_container}`}>
          {project?.featuredMediaType?.toLowerCase() === 'video' ? (
            <ReactPlayer
              width='100%'
              height='100%'
              className={classes['video']}
              url={project?.youTubeLink}
              light={true}
              config={{
                youtube: {
                  playerVars: { showinfo: 1, autoplay: 1, modestbranding: 1, rel: 0, controls: 1 }
                },
                facebook: {
                  appId: '12345'
                }
              }}
            />
          ) : (
            <img src={project?.featuredImage?.url} srcSet={srcSet} sizes={sizes} alt='project-image' />
          )}
        </div>
        <p className={`${classes.description}`}>{project?.summary}</p>
        <div className={`${classes.share}`}>
          <div className='highlight-text'>Share</div>
          <div className={`${classes.share_icon_wrapper}`}>
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <XIcon />
            </TwitterShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedInIcon />
            </LinkedinShareButton>
          </div>
        </div>
        <div className={`${classes.description}`} dangerouslySetInnerHTML={{ __html: project?.description }} />
        <div className={classes.gallery}>
          <Gallery media={project?.media} />
        </div>
      </section>
    </>
  )
}

export default ProjectDetail
