/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from '../../auth/ProtectedRoute'

const Dashboard = React.lazy(() => import('../../pages/cms/dashboard/Dashboard'))
const AddBanner = React.lazy(() => import('../../pages/cms/bannerPanel/BannerPanel'))
const ViewBanner = React.lazy(() => import('../../pages/cms/bannerPanel/ViewBanner'))
const Contact = React.lazy(() => import('../../pages/cms/contactPanel/ContactPanel'))
const GalleryManagement = React.lazy(() => import('../../pages/cms/galleryPanel/GalleryPanel'))
const AddProject = React.lazy(() => import('../../pages/cms/projectPanel/ProjectPanel'))
const ViewProject = React.lazy(() => import('../../pages/cms/projectPanel/ViewProject'))
const AddTestimonial = React.lazy(() => import('../../pages/cms/testimonialPanel/TestimonialPanel'))
const ViewTestimonial = React.lazy(() => import('../../pages/cms/testimonialPanel/ViewTestimonial'))
const AddTeam = React.lazy(() => import('../../pages/cms/teamPanel/TeamPanel'))
const ViewTeam = React.lazy(() => import('../../pages/cms/teamPanel/ViewTeam'))
const Media = React.lazy(() => import('../../pages/cms/Media/Media'))
const SiteSetting = React.lazy(() => import('../../pages/cms/siteSetting/SiteSetting'))
const PageNotFound = React.lazy(() => import('../../pages/404'))
const ServicePanel = React.lazy(() => import('../../pages/cms/servicePanel/ServicePanel'))
const AboutManagement = React.lazy(() => import('../../pages/cms/aboutPanel/AboutManagement'))
const TermsAndConditions = React.lazy(() => import('../../pages/cms/termsAndConditions/TermsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('../../pages/cms/privacyPolicy/PrivacyPolicy'))
const User = React.lazy(() => import('../../pages/cms/user'))

const CmsRoutes = () => {
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<Dashboard />} />

          <Route path='/addProject' element={<AddProject />} />
          <Route path='/viewProject' element={<ViewProject />} />
          <Route path='/editProject/:projectId' element={<AddProject editMode />} />

          <Route path='/addTeam' element={<AddTeam />} />
          <Route path='/viewTeam' element={<ViewTeam />} />
          <Route path='/editTeam/:teamId' element={<AddTeam editMode />} />

          <Route path='/contact' element={<Contact />} />

          <Route path='/addBanner' element={<AddBanner />} />
          <Route path='/viewBanner' element={<ViewBanner />} />
          <Route path='/editBanner/:bannerId' element={<AddBanner editMode />} />

          <Route path='/servicePanel' element={<ServicePanel />} />

          <Route path='/galleryManagement' element={<GalleryManagement />} />

          <Route path='/addTestimonial' element={<AddTestimonial />} />
          <Route path='/viewTestimonial' element={<ViewTestimonial />} />
          <Route path='/editTestimonial/:testimonialId' element={<AddTestimonial editMode />} />

          <Route path='/aboutManagement' element={<AboutManagement />} />

          <Route path='/sitemanagement' element={<SiteSetting />} />
          <Route path='/termsandconditions' element={<TermsAndConditions />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/media' element={<Media />} />
          <Route path='/user' element={<User />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
export default CmsRoutes
