/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'
import { cmsMediaActions, cmsDrawerActions } from '../index'
import parseFormData from '../../utils/parseFormData'
import viewFormData from '../../utils/viewFormData'

export const getAllMedia = createAsyncThunk('media/getAllMedia', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/media')
    return (await response).data.data
  } catch (err) {
    return rejectWithValue('Failed to get media!')
  }
})

export const postMedia = createAsyncThunk('media/postMedia', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/media', params.formData)
    params.resetForm ? params.resetForm() : (() => {})()
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to save media!')
  }
})
export const postEditorMedia = createAsyncThunk('media/postEditorMedia', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/media', params.formData)
    params.resetForm ? params.resetForm() : (() => {})()
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to save media!')
  }
})

export const postMultipleMedia = createAsyncThunk('media/postMultipleMedia', async (params, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line no-undef
    const responses = await Promise.all(
      params.formDataArray.map(async (formData) => {
        try {
          const response = await axios.post('/admin/media', formData)
          viewFormData(formData)
          return response.data.data
        } catch (err) {
          return rejectWithValue('Failed to save media!')
        }
      })
    )
    return responses
  } catch (err) {
    return rejectWithValue('Failed to save media!')
  }
})

export const updateMedia = createAsyncThunk('media/updateMedia', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.put('/admin/media', params.formData)
    params.closeDrawer()
    dispatch(cmsMediaActions.updateMedia(response.data.data))
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to update media!')
  }
})

export const deleteMedia = createAsyncThunk('media/deleteMedia', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`/admin/media/${params._id}`)
    dispatch(cmsMediaActions.deleteMedia({ _id: params._id }))
    params.closeDrawer()
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to update media!')
  }
})

const mediaSlice = createSlice({
  name: 'media',
  initialState: { list: [] },
  reducers: {
    addMedia(state, action) {
      if (action.payload) {
        state.push(action.payload)
      }
    },
    updateMedia(state, action) {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : (state.list[objectIndex] = action.payload)
          })()
    },
    deleteMedia(state, action) {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
    }
  },
  extraReducers: {
    [getAllMedia.pending]: (state) => {
      notification('info', { message: 'Info', description: 'Working on it.' })
    },
    [getAllMedia.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', {
        message: 'Success',
        description: 'Media fetched successfully.'
      })
    },
    [getAllMedia.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Failed to load media!'
      })
    },
    [postMedia.pending]: (state, action) => {
      notification('info', { message: 'Info', description: 'Saving media.' })
    },
    [postMedia.fulfilled]: (state, { payload }) => {
      const list = state.list
      const newList = [payload, ...list]
      state.list = newList
      notification('success', {
        message: 'Info',
        description: 'Media saved successfully.'
      })
    },
    [postMedia.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: 'Failed. Server or validation error.'
      })
    },
    [updateMedia.pending]: (state, action) => {
      notification('info', { message: 'Info', description: 'Updating media.' })
    },
    [updateMedia.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Info',
        description: 'Media updated successfully.'
      })
    },
    [updateMedia.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: 'Failed. Server or validation error.'
      })
    },
    [deleteMedia.pending]: (state, action) => {
      notification('info', { message: 'Info', description: 'Deleting media.' })
    },
    [deleteMedia.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Info',
        description: 'Media deleted successfully.'
      })
    },
    [deleteMedia.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: 'Failed. Server or validation error.'
      })
    },
    [postMultipleMedia.pending]: (state, action) => {
      notification('info', { message: 'Info', description: 'Saving media.' })
    },
    [postMultipleMedia.fulfilled]: (state, { payload }) => {
      payload.forEach((responseData) => {
        state.list = [...responseData, ...state.list]
      })
      notification('success', {
        message: 'Info',
        description: 'Media saved successfully.'
      })
    },
    [postMultipleMedia.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: ' Internal Server Error.'
      })
    }
  }
})
export default mediaSlice
