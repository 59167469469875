import classes from './GetStarted.module.css'
import PropTypes from 'prop-types'
// import { useNavigate } from 'react-router-dom'
import SplashIcon from '../../assets/splash-icon.svg'
import CTAButton from '../buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'

const CTA = ({ siteName }) => {
  const navigate = useNavigate()
  const services = [
    {
      id: 1,
      name: 'Production Management'
    },
    {
      id: 2,
      name: 'Video Production'
    },
    {
      id: 3,
      name: 'Photography'
    },
    {
      id: 4,
      name: 'Branding Photography'
    },
    {
      id: 5,
      name: 'Product Photography'
    },
    {
      id: 6,
      name: 'Videography'
    }
  ]
  return (
    <>
      <section className={classes['get-started']}>
        <div className={`${classes['info-section']} ${classes['column']}`}>
          <div className='highlight-text'>Dazzling Artistry by</div>
          <div className={classes['name']}>
            <h1 data-content='Revenge Bhatta'>{siteName || 'Revenge Bhatta'}</h1>
          </div>
        </div>
        <div className={`${classes['cta-section']} ${classes['column']}`}>
          <h1 className={classes['cta-title']}>Let&apos;s Create Memories</h1>
          <CTAButton
            onClick={() => {
              navigate('/contact')
            }}
          >
            Book Now
          </CTAButton>
        </div>
      </section>
      <div className={classes['services-container']}>
        <section className={classes['services-list']}>
          {services.map((service) => (
            <span key={service.id} className={`${classes['service']} small-text`}>
              <object type='image/svg+xml' data={SplashIcon} width='30' height='30'></object>
              {service.name}
            </span>
          ))}
        </section>
      </div>
    </>
  )
}

export default CTA

CTA.propTypes = {
  siteName: PropTypes.string
}
