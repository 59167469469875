import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getAllProject = createAsyncThunk('project/getClientAllProject', async (params, { rejectWithValue }) => {
  try {
    const queryParams = {}
    if (params?.isFeatured) {
      queryParams.isFeatured = params.isFeatured
    }
    if (params?.category) {
      queryParams.category = params.category
    }
    const response = await axios.get('/client/project', { params: queryParams })
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all project!')
  }
})

export const getOneProject = createAsyncThunk('project/getClientOneProject', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/client/project/${params.slug}`)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    status: 'PASSIVE',
    categories: [],
    list: [],
    selectedProject: null
  },
  extraReducers: {
    [getAllProject.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.list = payload
    },
    [getAllProject.rejected]: (state) => {
      state.status = 'REJECTED'
    },
    [getAllProject.pending]: (state) => {
      state.status = 'PENDING'
    },
    [getOneProject.pending]: (state) => {
      state.status = 'PENDING'
    },
    [getOneProject.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.selectedProject = payload
    },
    [getOneProject.rejected]: (state) => {
      state.status = 'REJECTED'
    }
  }
})
export default projectSlice
