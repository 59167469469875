import classes from './TermsAndConditions.module.css'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTermsAndConditions } from '../../../store/client/termsAndConditions'

function TermsAndConditions() {
  useEffect(() => {
    dispatch(getTermsAndConditions())
  }, [])
  const dispatch = useDispatch()
  const termsAndConditions = useSelector((state) => state.clientTermsAndConditions.termsAndConditions.details)

  return (
    <>
      <div className={classes['terms-and-conditions-container']}>
        <h1> Terms And Conditions</h1>

        <div className={classes['terms-and-conditions-details']} dangerouslySetInnerHTML={{ __html: termsAndConditions }}></div>
      </div>
    </>
  )
}

export default TermsAndConditions
