/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PrimaryButton from '../buttons/PrimaryButton'
import classes from './FeaturedProjects.module.css'
import { GoArrowRight } from 'react-icons/go'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Pagination } from 'swiper'
import ProjectPreviewCard from '../Card/ProjectPreview'
import { getAllProject } from '../../store/client/projectSlice'

const FeaturedProjects = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllProject({ isFeatured: true }))
  }, [])
  const projects = useSelector((state) => state.clientProject.list)
  const projectsToShow = projects?.slice(0, 6)
  return (
    <>
      <section className={classes['container']}>
        <div className={classes['header-wrapper']}>
          <div className={classes['title']}>
            <div className='highlight-text'>Projects</div>
            <h1>Explore My work</h1>
          </div>
          <PrimaryButton onClick={() => navigate('/projects')}>
            View All Works
            <span>
              <GoArrowRight />
            </span>
          </PrimaryButton>
        </div>
        <div className={classes['projects']}>
          <div className={classes['flex-container']}>
            {projectsToShow.map((item, index) => (
              <ProjectPreviewCard
                className='project-card'
                key={index}
                projectName={item?.title}
                media={item?.featuredMediaType?.toLowerCase() === 'image' ? item.featuredImage?.images?.[1] : item?.youTubeLink}
                projectDate={item?.projectYear}
                slug={item?.slug}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturedProjects
