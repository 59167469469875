import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PrimaryButton from '../buttons/PrimaryButton'
import classes from './ServicePreview.module.css'
import { GoArrowRight } from 'react-icons/go'
import ServiceCard from '../Card/ServiceCard'
import { getAllService } from '../../store/client/serviceSlice'

const ServicePreview = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllService())
  }, [])

  const services = useSelector((state) => state.clientService.list)

  const service = services?.slice(0, 3)
  return (
    <>
      <section className={classes['container']}>
        <div className={classes['header-wrapper']}>
          <div className={classes['title']}>
            <div className='highlight-text'>Services</div>
            <h1>My services</h1>
          </div>
          <PrimaryButton onClick={() => navigate('/services')}>
            View All Services
            <span>
              <GoArrowRight />
            </span>
          </PrimaryButton>
        </div>
        <div className={classes['services']}>
          {service?.map((data, index) => (
            <ServiceCard key={index} serviceName={data.serviceName} shortDescription={data.shortDescription} slug={data.slug} />
          ))}
        </div>
      </section>
    </>
  )
}

export default ServicePreview
