import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, useLocation } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))

function ScrollToTop({ children }) {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
)
reportWebVitals()
