function generateSrcSetAndSizes(image) {
  let srcSet = ''
  let sizes = ''

  if (image && image.images) {
    const images = image.images
    srcSet = images.map((img) => `${img?.url} ${img?.width}w`).join(', ')
    sizes = '(max-width: 768px) 100vw, 50vw'
  } else {
    // Handle the case when `image` or `image.images` is undefined
    // You might want to provide default values for `srcSet` and `sizes`
    srcSet = ''
    sizes = ''
  }

  return { srcSet, sizes }
}

export default generateSrcSetAndSizes
