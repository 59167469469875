import { configureStore } from '@reduxjs/toolkit'
import cmsAuthSlice from './cms/authSlice'
import cmsPwpSlice from './cms/pwpSlice'
import cmsAboutSlice from './cms/aboutSlice'
import cmsUserSlice from './cms/userSlice'
import cmsMediaSelectSlice from './cms/mediaSelectSlice'
import cmsMediaSlice from './cms/mediaSlice'
import cmsDrawerSlice from './cms/drawerSlice'
import cmsTeamSlice from './cms/teamSlice'
import cmsTestimonialSlice from './cms/testimonialSlice'
import cmsBannerSlice from './cms/bannerSlice'
import cmsProjectSlice from './cms/projectSlice'
import cmsContactSlice from './cms/contactSlice'
import cmsSiteSettingSlice from './cms/siteSettingSlice'
import cmsServiceSlice from './cms/serviceSlice'
import cmsTermsAndConditionsSlice from './cms/termsAndConditions'
import cmsPrivacyPolicySlice from './cms/privacyPolicy'

import clientBannerSlice from './client/bannerSlice'
import clientProjectSlice from './client/projectSlice'
import clientTestimonialSlice from './client/testimonialSlice'
import clientTeamSlice from './client/teamSlice'
import clientServiceSlice from './client/serviceSlice'
import clientContactSlice from './client/contactSlice'
import clientAboutSlice from './client/aboutSlice'
import clientSiteSettingSlice from './client/siteSettingSlice'
import clientTermsAndConditionsSlice from './client/termsAndConditions'
import clientPrivacyPolicySlice from './client/privacyPolicy'

const store = configureStore({
  reducer: {
    cmsAuth: cmsAuthSlice.reducer,
    cmsUser: cmsUserSlice.reducer,
    cmsPwp: cmsPwpSlice.reducer,
    cmsMedia: cmsMediaSlice.reducer,
    cmsBanner: cmsBannerSlice.reducer,
    cmsDrawer: cmsDrawerSlice.reducer,
    cmsTeam: cmsTeamSlice.reducer,
    cmsTestimonial: cmsTestimonialSlice.reducer,
    cmsMediaSelect: cmsMediaSelectSlice.reducer,
    cmsProject: cmsProjectSlice.reducer,
    cmsContact: cmsContactSlice.reducer,
    cmsSiteSetting: cmsSiteSettingSlice.reducer,
    cmsService: cmsServiceSlice.reducer,
    cmsAbout: cmsAboutSlice.reducer,
    cmsTermsAndConditions: cmsTermsAndConditionsSlice.reducer,
    cmsPrivacyPolicy: cmsPrivacyPolicySlice.reducer,
    // client
    clientBanner: clientBannerSlice.reducer,
    clientProject: clientProjectSlice.reducer,
    clientTestimonial: clientTestimonialSlice.reducer,
    clientTeam: clientTeamSlice.reducer,
    clientService: clientServiceSlice.reducer,
    clientContact: clientContactSlice.reducer,
    clientAbout: clientAboutSlice.reducer,
    clientSiteSetting: clientSiteSettingSlice.reducer,
    clientTermsAndConditions: clientTermsAndConditionsSlice.reducer,
    clientPrivacyPolicy: clientPrivacyPolicySlice.reducer
  }
})

export default store
export const cmsAuthActions = cmsAuthSlice.actions
export const cmsUserActions = cmsUserSlice.actions
export const cmsPwpActions = cmsPwpSlice.actions
export const cmsMediaActions = cmsMediaSlice.actions
export const cmsDrawerActions = cmsDrawerSlice.actions
export const cmsTeamActions = cmsTeamSlice.actions
export const cmsTestimonialActions = cmsTestimonialSlice.actions
export const cmsMediaSelectActions = cmsMediaSelectSlice.actions
export const cmsProjectActions = cmsProjectSlice.actions
export const cmsContactActions = cmsContactSlice.actions
export const cmsSiteSettingActions = cmsSiteSettingSlice.actions
export const cmsServiceActions = cmsServiceSlice.actions
export const cmsAboutActions = cmsAboutSlice.actions
export const cmsTermsAndConditions = cmsTermsAndConditionsSlice.actions
export const cmsPrivacyPolicy = cmsPrivacyPolicySlice.actions

export const clientBannerActions = clientBannerSlice.actions
export const clientProjectActions = clientProjectSlice.actions
export const clientTestimonialActions = clientTestimonialSlice.actions
export const clientTeamActions = clientTeamSlice.actions
export const clientServiceActions = clientServiceSlice.actions
export const clientContactActions = clientContactSlice.actions
export const clientAboutActions = clientAboutSlice.actions
export const clientSiteSettingActions = clientSiteSettingSlice.actions
export const clientTermsAndConditions = clientTermsAndConditionsSlice.actions
export const clientPrivacyPolicy = clientPrivacyPolicySlice.actions
