import classes from './Banner.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBanner } from '../../store/client/bannerSlice'
import { GoArrowUpRight } from 'react-icons/go'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Pagination, Navigation } from 'swiper/modules'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

const Banner = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBanner())
  }, [dispatch])

  const banners = useSelector((state) => state.clientBanner.list)

  // Conditional rendering
  return (
    <>
      {banners.length > 0 && ( // Check if banners array is not empty
        <div className={classes.container}>
          <Swiper
            autoplay={{ delay: 4500 }}
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            spaceBetween={40}
            navigation
            pagination={{ clickable: true }}
            loop
            className={classes.swiper}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <div className={classes.banner}>
                  {banner?.projectID?.featuredMediaType === 'Image' ? (
                    <img src={banner?.projectID?.featuredImage?.url} alt='' />
                  ) : (
                    <ReactPlayer
                      width='100%'
                      height='100%'
                      className={classes.video}
                      url={banner?.projectID?.youTubeLink}
                      light={
                        banner?.projectID?.youTubeLink
                          ? `https://img.youtube.com/vi/${banner?.projectID?.youTubeLink.split('v=')[1]}/maxresdefault.jpg`
                          : true
                      }
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1, autoplay: 1, modestbranding: 1, rel: 0, controls: 1 }
                        },
                        facebook: {
                          appId: '12345'
                        }
                      }}
                    />
                  )}
                </div>
                <div className={classes['project-wrapper']}>
                  <div className={classes.project}>
                    <div className={`${classes.name} title`}>{banner?.title || banner?.projectID?.title}</div>
                    <div className={`${classes.date} small-text`}>{banner?.projectID?.projectYear}</div>
                  </div>
                  <div className={classes.link}>
                    <a className='small-text' onClick={() => navigate(`/projects/${banner?.projectID?.slug}`)} target='' rel='noreferrer'>
                      View Project
                      <GoArrowUpRight style={{ width: '20px', height: '20px' }} />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  )
}

export default Banner
