/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'
import { cmsTeamActions } from '../index'
export const postTeam = createAsyncThunk('team/postTeam', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/admin/team', params.formData)
    dispatch(cmsTeamActions.addOneTeamToList(response.data.data))

    params.resetForm()
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to post team!')
  }
})
export const getAllTeam = createAsyncThunk('team/getAllTeam', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/admin/team')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Falied to load team.')
  }
})
export const getOneTeam = createAsyncThunk('team/getOneTeam', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/team/${params._id}`)
    params.fillForm(response.data.data)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed To Fetch Team Info')
  }
})

export const deleteTeam = createAsyncThunk('team/deleteTeam', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`/admin/team/${params._id}`)
    dispatch(cmsTeamActions.deleteTeam(response.data.data))
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to delete team!')
  }
})

export const patchTeam = createAsyncThunk('team/patchTeam', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch(`/admin/team/${params._id}`, params.formData)
    if (params.enableSwitch) {
      params.enableSwitch()
    } else {
      params.navigate()
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to update data!')
  }
})

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    list: []
  },
  reducers: {
    deleteTeam(state, action) {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
    },
    addToTeamList(state, action) {
      state.list = action.payload
    },
    addOneTeamToList(state, action) {
      state.list = [action.payload, ...state.list]
    }
  },
  extraReducers: {
    [postTeam.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [postTeam.fulfilled]: (state, action) => {
      notification('success', { message: 'Saved Successfully.' })
    },
    [postTeam.rejected]: (state, action) => {
      notification('error', { message: 'Error Saving Data!' })
    },
    [getOneTeam.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [getOneTeam.fulfilled]: (state, action) => {
      notification('success', { message: 'Fetched Successfully.' })
    },
    [getOneTeam.rejected]: (state, action) => {
      notification('error', { message: 'Error Fetching Data!' })
    },
    [getAllTeam.pending]: (state, action) => {
      notification('info', { message: 'Working on It!' })
    },
    [getAllTeam.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', { message: 'Fetched Successfully.' })
    },
    [getAllTeam.rejected]: (state, action) => {
      notification('error', { message: 'Error Fetching Data!' })
    },
    [patchTeam.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [patchTeam.fulfilled]: (state, action) => {
      notification('success', { message: 'Success.', description: 'Successfully updated.' })
    },
    [patchTeam.rejected]: (state) => {
      notification('error', { message: 'Error!', description: "Team doesn't exist or validation error! " })
    },
    [deleteTeam.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [deleteTeam.fulfilled]: (state) => {
      notification('success', { message: 'Success.', description: 'Successfully deleted.' })
    },
    [deleteTeam.rejected]: (state) => {
      notification('error', { message: 'Error!', description: "Team doesn't exist or validation error! " })
    }
  }
})

export default teamSlice
