import React from 'react'
import classes from './Testimonial.module.css'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import TestimonialCard from '../Card/TestimonialCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import PropTypes from 'prop-types'
SwiperCore.use([Navigation, Pagination])

const Testimonials = ({ testimonials }) => {
  const swiperRef = React.useRef(null)

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  return (
    <>
      <section className={classes['container']}>
        <div className={classes['header-wrapper']}>
          <div className={classes['title']}>
            <div className='highlight-text'>Testimonials</div>
            <h1>What my clients say</h1>
          </div>
          <div className={classes['custom-navigation']}>
            <div className={classes['custom-prev']} onClick={handlePrev}>
              <LeftCircleOutlined />
            </div>
            <div className={classes['custom-next']} onClick={handleNext}>
              <RightCircleOutlined />
            </div>
          </div>
        </div>
        <div className={classes['testimonials']}>
          <Swiper
            className={classes['swiper-wrapper']}
            cssMode={true}
            // slidesPerView={3}
            mousewheel={true}
            ref={swiperRef}
            navigation={{ prevEl: '.custom-prev', nextEl: '.custom-next' }}
            pagination={{ el: '.custom-pagination', clickable: true }}
            breakpoints={{
              // When window width is >= 320px
              576: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              // When window width is >= 768px
              768: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              // When window width is >= 1024px
              1024: {
                slidesPerView: 3,
                spaceBetween: 40
              }
            }}
          >
            {testimonials?.map((item, index) => (
              <SwiperSlide key={index} style={{ minWidth: '400px' }}>
                <TestimonialCard
                  className='testimonial-card'
                  name={item?.name}
                  image={item?.image}
                  designation={item?.designation}
                  organizationName={item?.organizationName}
                  testimonial={item?.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default Testimonials

Testimonials.propTypes = {
  testimonials: PropTypes.array
}
