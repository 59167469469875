/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'
import { cmsServiceActions } from '../index'
import parseFormData from '../../utils/parseFormData'

export const getAllService = createAsyncThunk('service/getAllService', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/service')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all service!')
  }
})

export const postService = createAsyncThunk('service/postService', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/service', params.formData)
    params.resetForm()
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add service!')
  }
})
export const patchService = createAsyncThunk('service/putService', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch(`/admin/service/${params._id}`, params.formData)
    if (params.enableSwitch) {
      params.enableSwitch()
    } else {
      const formData = parseFormData(params.formData)
      params.resetForm()
      dispatch(cmsServiceActions.updateService(formData))
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Update failed!')
  }
})

export const getOneService = createAsyncThunk('service/getOneService', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/service/${params.serviceId}`)
    params.fillForm(response.data.data)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

export const deleteService = createAsyncThunk('service/deleteService', async (params, { rejectWithValue }) => {
  try {
    const response = axios.delete(`/admin/service/${params._id}`)
    return (await response).data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    list: [],
    editItem: null
  },
  reducers: {
    editItem(state, action) {
      state.editItem = action.payload
    },
    initializeEditItem(state, action) {
      state.editItem = null
    },
    updateService(state, action) {
      const objectIndex = state.list.findIndex((item) => item._id === action.payload._id)
      objectIndex < 0
        ? (() => {})()
        : (() => {
            state.list[objectIndex].serviceName = action.payload.serviceName
            state.list[objectIndex].isPublish = action.payload.isPublish
            state.list[objectIndex].description = action.payload.description
          })()
    }
  },
  extraReducers: {
    [getAllService.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.'
      })
    },
    [getAllService.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Cannot fetch services!'
      })
    },
    [getAllService.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postService.fulfilled]: (state, action) => {
      state.list.push(action.payload)
      notification('success', {
        message: 'Success.',
        description: 'Service saved successfully.'
      })
    },
    [postService.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! '
      })
    },
    [patchService.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [patchService.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Success.',
        description: 'Successfully updated.'
      })
    },
    [patchService.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        // eslint-disable-next-line quotes
        description: "Service doesn't exist or validation error! "
      })
    },
    [deleteService.pending]: (state, action) => {
      notification('info', { message: 'Working on it!.' })
    },
    [deleteService.fulfilled]: (state, action) => {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
      notification('success', {
        message: 'Success.',
        description: 'Deleted successfully.'
      })
    },
    [deleteService.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        // eslint-disable-next-line quotes
        description: "Service doesn't exist or server error! "
      })
    },
    [getOneService.pending]: (state) => {
      notification('info', { message: 'Fetching service info!' })
    },
    [getOneService.fulfilled]: (state) => {
      notification('success', { message: 'Successfully fetched.' })
    },
    [getOneService.rejected]: (state) => {
      notification('error', { message: 'Server error!' })
    }
  }
})
export default serviceSlice
