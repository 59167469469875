import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getAllTestimonial = createAsyncThunk('testimonial/getClientAllTestimonial', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/testimonial')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all testimonial!')
  }
})

const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState: {
    status: 'PASSIVE',
    testimonial: []
  },
  extraReducers: {
    [getAllTestimonial.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.testimonial = payload
    },
    [getAllTestimonial.rejected]: (state) => {
      state.status = 'REJECTED'
    },
    [getAllTestimonial.pending]: (state) => {
      state.status = 'PENDING'
    }
  }
})
export default testimonialSlice
