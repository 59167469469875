function PhoneIcon() {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='black' />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='#1C1C21' />
      <g clipPath='url(#clip0_1154_140)'>
        <path
          d='M30.4296 25.1327L26.0546 23.2577C25.8677 23.178 25.6599 23.1612 25.4627 23.2099C25.2654 23.2585 25.0893 23.3698 24.9608 23.5272L23.0233 25.8944C19.9825 24.4607 17.5353 22.0136 16.1017 18.9728L18.4689 17.0353C18.6266 16.907 18.7382 16.7309 18.7868 16.5336C18.8354 16.3362 18.8185 16.1284 18.7384 15.9415L16.8634 11.5665C16.7755 11.365 16.6202 11.2006 16.4241 11.1015C16.228 11.0024 16.0034 10.9748 15.7891 11.0235L11.7266 11.961C11.52 12.0087 11.3357 12.125 11.2037 12.291C11.0718 12.4569 11 12.6627 11 12.8747C11 22.8944 19.1212 31 29.1253 31C29.3374 31.0001 29.5433 30.9284 29.7093 30.7964C29.8753 30.6644 29.9917 30.4801 30.0394 30.2734L30.9769 26.2109C31.0253 25.9955 30.9971 25.7701 30.8972 25.5733C30.7973 25.3765 30.632 25.2207 30.4296 25.1327Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1154_140'>
          <rect width='20' height='20' fill='white' transform='translate(11 11)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PhoneIcon
