import Axios from 'axios'

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  config.headers['Authorization'] = 'Bearer ' + token
  config.headers['Accept'] = 'application/json'
  return config
})

export default instance
