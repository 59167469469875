import classes from './TestimonialCard.module.css'
import PropTypes from 'prop-types'

const TestimonialCard = ({ image, name, designation, testimonial, organizationName, className }) => {
  return (
    <>
      <div className={`${classes['card']} ${className ? className : ''}`}>
        <div className={classes['client-detail']}>
          <div className={classes['client-image']}>
            <img src={image?.images?.[1].url || image.url || ''} alt={name} />
          </div>
          <div className={classes['client-info']}>
            <h3>{name}</h3>
            <div className={classes['client-designation']}>
              {designation}
              <p>{organizationName}</p>
            </div>
          </div>
        </div>
        <div className={classes['client-testimonial']}>
          <p>{testimonial}</p>
        </div>
      </div>
    </>
  )
}

TestimonialCard.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  testimonial: PropTypes.string,
  organizationName: PropTypes.string,
  className: PropTypes.string
}

export default TestimonialCard
