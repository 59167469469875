import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getPrivacyPolicy = createAsyncThunk('privacyPolicy/getClientPrivacyPolicy', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/privacypolicy')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all privacyPolicy!')
  }
})

const privacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState: {
    privacyPolicy: {}
  },
  extraReducers: {
    [getPrivacyPolicy.fulfilled]: (state, { payload }) => {
      state.privacyPolicy = payload
    }
  }
})
export default privacyPolicySlice
