import classes from './Services.module.css'
import ServiceCard from '../Card/ServiceCard'
import PropTypes from 'prop-types'
// images
// import CameraIcon from '../../assets/camera-icon.png'
// import VideoIcon from '../../assets/video-icon.png'

const Services = ({ services }) => {
  return (
    <>
      <section className={classes['container']}>
        <div className={classes['services']}>
          {services?.map((item, index) => (
            <ServiceCard
              className='service-card'
              key={index}
              serviceName={item.serviceName}
              shortDescription={item.shortDescription}
              slug={item?.slug}
            />
          ))}
        </div>
      </section>
    </>
  )
}

export default Services

Services.propTypes = {
  services: PropTypes.array
}
