import RecentWorks from '../../../components/RecentWorks'
import TalkProject from '../../../components/TalkProject'
import Top from '../../../components/Top'
import classes from './ServiceDetail.module.css'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneService } from '../../../store/client/serviceSlice'
import { getAllProject } from '../../../store/client/projectSlice'
import Seo from '../../../utils/seo'
const ServiceDetail = () => {
  const { serviceType } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOneService({ slug: serviceType }))
  }, [])

  const service = useSelector((state) => state.clientService.selectedService)
  const recentProjects = useSelector((state) => state.clientProject.list)

  useEffect(() => {
    dispatch(getAllProject({ category: service?.serviceName }))
  }, [service])
  return (
    <>
      <Seo
        title={service?.serviceName || 'Revenge Bhatta : Service'}
        description={
          service?.shortDescription ||
          'We offers expert photography, videography, and production management services, ensuring every moment is immortalized with creativity and finesse.'
        }
      />
      <Top title={service?.serviceName || 'services'} />
      <div className={classes['content']} dangerouslySetInnerHTML={{ __html: service?.description }} />
      <RecentWorks recentProjects={recentProjects} />
      <TalkProject />
    </>
  )
}

export default ServiceDetail
