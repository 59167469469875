/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'
export const getAllBanner = createAsyncThunk('banner/getAllBanner', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/banner')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all advertisement!')
  }
})

export const postBanner = createAsyncThunk('banner/postBanner', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/banner', params.formData)
    params.resetForm()
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add banner!')
  }
})
export const patchBanner = createAsyncThunk('banner/putBanner', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.patch(`/admin/banner/${params._id}`, params.formData)
    if (params.enableSwitch) {
      params.enableSwitch()
    } else {
      params.navigate()
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Update failed!')
  }
})

export const getOneBanner = createAsyncThunk('banner/getOneBanner', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/banner/${params.bannerId}`)
    // params.fillForm(response.data.data)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

export const deleteBanner = createAsyncThunk('banner/deleteBanner', async (params, { rejectWithValue }) => {
  try {
    const response = axios.delete(`/admin/banner/${params._id}`)
    return (await response).data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    list: []
  },
  extraReducers: {
    [getAllBanner.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.'
      })
    },
    [getAllBanner.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Cannot fetch banners!'
      })
    },
    [getAllBanner.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postBanner.fulfilled]: (state, action) => {
      state.list.push(action.payload)
      notification('success', {
        message: 'Success.',
        description: 'Banner saved successfully.'
      })
    },
    [postBanner.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! '
      })
    },
    [postBanner.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [patchBanner.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Success.',
        description: 'Successfully updated.'
      })
    },
    [patchBanner.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: "Banner doesn't exist or validation error! "
      })
    },
    [deleteBanner.pending]: (state, action) => {
      notification('info', { message: 'Working on it!.' })
    },
    [deleteBanner.fulfilled]: (state, action) => {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
      notification('success', {
        message: 'Success.',
        description: 'Deleted successfully.'
      })
    },
    [deleteBanner.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: "Banner doesn't exist or server error! "
      })
    },
    [getOneBanner.pending]: (state) => {
      notification('info', { message: 'Fetching banner info!' })
    },
    [getOneBanner.fulfilled]: (state) => {
      notification('success', { message: 'Successfully fetched.' })
    },
    [getOneBanner.rejected]: (state) => {
      notification('error', { message: 'Server error!' })
    }
  }
})
export default bannerSlice
