/* eslint-disable quotes */
import siteLogo from '../assets/revenge-bhatta.jpeg'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'
function Seo(props) {
  const { title, description, image, url, type } = props
  return (
    <Helmet>
      {/* Facebook tags */}
      <meta property='og:title' content={title ? title : 'Revenge Bhatta : Lets Create Memories'} />
      <meta
        property='og:description'
        content={
          description
            ? description
            : "Revenge Bhatta: Let's Create Memories specializes in capturing life's most cherished moments with unmatched artistry and passion."
        }
      />
      <meta property='og:image' content={image ? image : siteLogo} />
      <meta property='og:url' content={url ? url : window.location.href} />
      <meta property='og:type' content={type ? type : 'website'} />
      {/* Twitter tags */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title ? title : 'Revenge Bhatta : Lets Create Memories'} />
      <meta
        name='twitter:description'
        content={
          description
            ? description
            : "Revenge Bhatta: Let's Create Memories specializes in capturing life's most cherished moments with unmatched artistry and passion."
        }
      />
      <meta name='twitter:image' content={image ? image : siteLogo} />
      {/* LinkedIn tags */}
      <meta property='og:site_name' content='Your Site Name' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title ? title : 'Revenge Bhatta : Lets Create Memories'} />
      <meta property='og:description' content={description ? description : 'Your description for LinkedIn'} />
      <meta property='og:image' content={image ? image : siteLogo} />
      <meta property='og:url' content={url ? url : window.location.href} />
      {/* Google Search */}
      <title>{title ? title : 'Revenge Bhatta : Lets Create Memories'}</title>
      <meta
        name='description'
        content={
          description
            ? description
            : "Revenge Bhatta: Let's Create Memories specializes in capturing life's most cherished moments with unmatched artistry and passion."
        }
      />
      <meta name='google' content='notranslate' />
      {/* Services */}
      <meta name='service' content='Photography, Videography, Production Management' /> {/* Services Meta Tag */}
      {/* Keywords */}
      <meta
        name='keywords'
        content='
    photography, videography, production management, photoshoots, cinematography, event production, video editing, 
    professional photography, professional videography, film production, commercial photography, wedding videography, 
    portrait photography, corporate videography, documentary filmmaking, post-production, drone photography, 
    fashion photography, music video production, promotional videos, advertising photography, product videography, 
    visual storytelling, creative direction, camera operation, lighting design, audio production, video marketing, 
    brand promotion, digital content creation, social media campaigns, stock photography, stock footage, 
    travel photography, nature videography, educational videos, live event coverage, aerial photography, 
    underwater videography, studio photography, studio lighting, green screen effects, time-lapse photography, 
    slow-motion videography, multimedia production, film direction, scriptwriting, location scouting, 
    pre-production planning, video distribution, cinematographic techniques, video compression, 
    color grading, sound editing, audio mixing, film festivals, video contests, photography workshops, 
    videography courses, production equipment, camera gear, video software, photography apps, 
    industry trends, creative inspiration, portfolio building, client testimonials, project showcases
  '
      />
      {/* Keywords Meta Tag */}
      {/* Description */}
      <meta
        name='description'
        content='Professional photography, videography, and production management services for capturing and creating memorable moments. Specializing in photoshoots, cinematography, event production, and video editing.'
      />{' '}
      {/* Description Meta Tag */}
      {/* Author */}
      <meta name='author' content='Revenge Bhatta' /> {/* Author Meta Tag */}
      {/* WhatsApp */}
      <meta property='og:whatsapp' content='whatsapp://send?text=Your%20message%20here:%20{encodeURIComponent(window.location.href)}' />
      <meta name='image' content={image ? image : siteLogo} />
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string
}
