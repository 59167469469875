import classes from './index.module.css'
import { AiFillCloseCircle } from 'react-icons/ai'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import normalizeUrl from '../../utils/normalizeUrl'

const MobileNav = ({ navClickhandler, className, siteLogo, about }) => {
  return (
    <div className={`${classes['mobile-nav']} ${className}`}>
      <div className={classes['mobile-top']}>
        <div className={classes['logo']}>
          <div className={classes['icon']}>
            {siteLogo ? (
              <img
                src={(siteLogo !== null || undefined) && siteLogo !== undefined ? siteLogo?.url : (() => {})()}
                alt='logo of the website'
              />
            ) : null}
          </div>
        </div>
        <div className={classes['close-nav']} onClick={navClickhandler}>
          <AiFillCloseCircle size={36} color='black' style={{ backgroundColor: 'white', borderRadius: '20px' }} />
        </div>
      </div>
      <ul>
        <li
          onClick={navClickhandler}
          // onHoverStart={{ scale: '15' }}
          // onHoverEnd={{ scale: '1' }}
        >
          <NavLink className={(navData) => (navData.isActive ? classes['mobile-active'] : classes['mobile-passive'])} to='/'>
            Home
          </NavLink>
        </li>
        {about?.portfolioLink && (
          <li>
            <NavLink
              className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])}
              to={normalizeUrl(about?.portfolioLink)}
              target='_blank'
              rel='noopener noreferrer'
            >
              Portfolio
            </NavLink>
          </li>
        )}
        <li onClick={navClickhandler}>
          <NavLink className={(navData) => (navData.isActive ? classes['mobile-active'] : classes['mobile-passive'])} to='/about-me'>
            About Me
          </NavLink>
        </li>
        <li onClick={navClickhandler}>
          <NavLink className={(navData) => (navData.isActive ? classes['mobile-active'] : classes['mobile-passive'])} to='/services'>
            Services
          </NavLink>
        </li>
        <li onClick={navClickhandler}>
          <NavLink className={(navData) => (navData.isActive ? classes['mobile-active'] : classes['mobile-passive'])} to='/projects'>
            Projects
          </NavLink>
        </li>
        <li onClick={navClickhandler}>
          <NavLink className={(navData) => (navData.isActive ? classes['mobile-active'] : classes['mobile-passive'])} to='/contact'>
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

// Define propTypes for the MobileNav
MobileNav.propTypes = {
  navClickhandler: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  siteLogo: PropTypes.string,
  about: PropTypes.object
}

export default MobileNav
