import { Button } from 'antd'
import classes from './Buttons.module.css'
import PropTypes from 'prop-types'
const CTAButton = (props) => {
  return (
    <Button className={classes['cta-btn']} type='primary' {...props}>
      {props.children}
    </Button>
  )
}

CTAButton.propTypes = {
  children: PropTypes.any
}

export default CTAButton
