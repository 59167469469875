/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const getAllContact = createAsyncThunk('/admin/contact/getAllContact', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/inquiry')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all contact!')
  }
})

export const deleteContact = createAsyncThunk('delete/deleteContact', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/admin/inquiry/${params._id}`)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})
const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    list: []
  },
  extraReducers: {
    [getAllContact.fulfilled]: (state, { payload }) => {
      state.list = payload
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.'
      })
    },
    [getAllContact.rejected]: (state) => {
      notification('error', {
        message: 'Error!',
        description: 'Cannot fetch contact!'
      })
    },
    [deleteContact.pending]: (state, action) => {
      notification('info', { message: 'Working on it!.' })
    },
    [deleteContact.fulfilled]: (state, action) => {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
      notification('success', {
        message: 'Success.',
        description: 'Deleted successfully.'
      })
    },
    [deleteContact.rejected]: (state, action) => {
      notification('error', {
        message: 'Error!',
        description: "Conatct doesn't exist or server error! "
      })
    }
  }
})
export default contactSlice
