import classes from './AboutMe.module.css'
import PropTypes from 'prop-types'
import FacebookIcon from '../../components/Icon/Facebook'
import XIcon from '../../components/Icon/X'
import InstagramIcon from '../../components/Icon/Instagram'
import LinkedInIcon from '../../components/Icon/LinkedIn'
import generateSrcSetAndSizes from '../../utils/generateSrcSetAndSizes'
import normalizeUrl from '../../utils/normalizeUrl'

const AboutMe = ({ about }) => {
  const { aboutMe, image, facebook, instagram, linkedIn, twitter } = about || {}
  const { srcSet, sizes } = generateSrcSetAndSizes(image)

  return (
    <>
      <section className={classes['about-me']}>
        {/* content wrapper */}
        <div className={classes['content-wrapper']}>
          {/* profile image */}
          <div className={classes['profile-image']}>
            <img src={image?.url || ''} srcSet={srcSet} sizes={sizes} alt='profile image' />
          </div>
          {/* description wrapper */}
          <div className={classes['description-container']}>
            {/* about me description */}
            <div className={classes['about-content']}>
              <h2>Few Words about me</h2>
              <p>{aboutMe}</p>
            </div>
            {/* social Media */}
            <div className={classes['social-media-wrapper']}>
              <div className={`${classes['follow-label']} highlight-text `}>Follow me on</div>
              <div className={classes['social-media']}>
                <a href={normalizeUrl(facebook)} target='blank'>
                  <FacebookIcon className={classes['facebook-icon']} />
                </a>
                <a href={normalizeUrl(instagram)} target='blank'>
                  <InstagramIcon className={classes['instagram-icon']} />
                </a>
                <a href={normalizeUrl(linkedIn)} target='blank'>
                  <LinkedInIcon className={classes['linkedin-icon']} />
                </a>
                <a href={normalizeUrl(twitter)} target='blank'>
                  <XIcon className={classes['x-icon']} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutMe

AboutMe.propTypes = {
  about: PropTypes.object
}
