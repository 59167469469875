import classes from './ProjectPreview.module.css'
import placeholder from '../../assets/image-placeholder.png'
import PropTypes from 'prop-types'
import { GoArrowUpRight } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'

// Extract the video ID from the YouTube link
function getYoutubeVideoId(url) {
  if (!url) return null // Handle case where url is undefined or null
  const videoIdMatch = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?vi=))([^&?]+)/)
  return videoIdMatch && videoIdMatch[1]
}

// Construct the thumbnail URL using the video ID
function getYoutubeThumbnailUrl(videoId) {
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}

const ProjectPreviewCard = ({ media, projectName, projectDate, slug, className }) => {
  const navigate = useNavigate()
  let thumbnailUrl = ''
  if (typeof media === 'string' && media !== '' && media !== null) {
    const videoId = getYoutubeVideoId(media)
    thumbnailUrl = getYoutubeThumbnailUrl(videoId)
  }
  return (
    <>
      <div className={`${classes['card']} ${className ? className : ''}`}>
        <div className={classes['card-image']}>
          <img src={media?.url || thumbnailUrl || placeholder} alt={media?.alt || ''} />
        </div>
        <div className={classes['card-content']}>
          <div className={classes['project-wrapper']}>
            <div className={`${classes['title']} title`}>{projectName}</div>
            <div className={`${classes['date']} small-text`}>{projectDate}</div>
          </div>
          <div className={classes['link']}>
            <a className='small-text' onClick={() => navigate(`/projects/${slug}`)} target='_blank' rel='noreferrer'>
              View Project
              <GoArrowUpRight style={{ width: '20px', height: '20px' }} />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

ProjectPreviewCard.propTypes = {
  media: PropTypes.any,
  projectName: PropTypes.string,
  projectDate: PropTypes.number,
  slug: PropTypes.string,
  className: PropTypes.string
}

export default ProjectPreviewCard
