import classes from './Team.module.css'
import TeamCard from '../Card/TeamCard'
import PropTypes from 'prop-types'

const Team = ({ team }) => {
  return (
    <section className={classes['container']}>
      <div className={classes['header-wrapper']}>
        <div className={classes['title']}>
          <div className='highlight-text'>Team</div>
          <h1>My Team</h1>
        </div>
      </div>
      <div className={classes['content-wrapper']}>
        {team?.map((member, index) => (
          <TeamCard key={index} {...member} />
        ))}
      </div>
    </section>
  )
}

export default Team

Team.propTypes = {
  team: PropTypes.array
}
