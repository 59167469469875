/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const getTermsAndConditions = createAsyncThunk('termsAndConditions/getTermsAndConditions', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/termsandconditions')
    if (response?.data?.data) {
      params.fillForm(response.data.data)
    }

    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to terms and conditions!')
  }
})

export const postTermsAndConditions = createAsyncThunk('termsAndConditions/postTermsAndConditions', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/termsandconditions', params.formData)
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add terms and conditons settings!')
  }
})

const webtermsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState: {
    termsAndConditions: null
  },
  extraReducers: {
    [getTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.termsAndConditions = payload
    },
    [getTermsAndConditions.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Cannot fetch terms and conditons!' })
    },
    [postTermsAndConditions.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postTermsAndConditions.fulfilled]: (state, action) => {
      state.termsAndConditions = action.payload
      notification('success', { message: 'Success.', description: 'terms and conditions saved successfully.' })
    },
    [postTermsAndConditions.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Server error or validation error! ' })
    }
  }
})
export default webtermsAndConditionsSlice
