/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const postContact = createAsyncThunk('contact/postClientContact', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/client/inquiry', params.formData)
    params.resetForm()
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Error Connecting to Server!')
  }
})

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    status: 'PASSIVE'
  },
  extraReducers: {
    [postContact.pending]: (state) => {
      state.status = 'PENDING'
    },
    [postContact.fulfilled]: (state) => {
      state.status = 'ACTIVE'
    },
    [postContact.rejected]: (state) => {
      state.status = 'REJECTED'
    }
  }
})
export default contactSlice
