function MailIcon() {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='black' />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='#1C1C21' />
      <g clipPath='url(#clip0_1154_240)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.5741 13.9469C13.6667 13.9063 13.7618 13.8853 13.8519 13.8853H28.6667C28.7569 13.8853 28.8519 13.9062 28.9445 13.9469L21.2593 19.8402L13.5741 13.9469ZM11.576 13.0941C11.4352 13.1599 11.3076 13.2596 11.2065 13.3915C10.9941 13.6684 10.9491 14.021 11.0552 14.3278C11.019 14.5194 11.0001 14.7156 11.0001 14.9139V27.0861C11.0001 28.6663 12.1973 30.1148 13.8519 30.1148H28.6667C30.3214 30.1148 31.5186 28.6663 31.5186 27.0861V14.9139C31.5186 14.7156 31.4997 14.5193 31.4635 14.3276C31.5695 14.0208 31.5244 13.6684 31.3121 13.3915C31.211 13.2596 31.0835 13.16 30.9427 13.0942C30.4353 12.3743 29.6283 11.8853 28.6667 11.8853H13.8519C12.8904 11.8853 12.0834 12.3743 11.576 13.0941ZM29.5186 16.027V27.0861C29.5186 27.7374 29.0491 28.1148 28.6667 28.1148H13.8519C13.4696 28.1148 13.0001 27.7374 13.0001 27.0861V16.0271L20.6508 21.8939C21.0098 22.1692 21.5088 22.1692 21.8678 21.8939L29.5186 16.027Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1154_240'>
          <rect width='20.5185' height='18.2295' fill='white' transform='translate(11 11.8853)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MailIcon
