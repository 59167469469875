import { notification } from 'antd'

notification.config({
  placement: 'topRight',
  bottom: 0,
  left: 0,
  duration: 3.5,
  rtl: false
})
const openNotificationWithIcon = (type, details) => {
  notification[type]({
    message: <div style={{ color: type === 'error' ? 'red' : 'green' }}>{details.message}</div>,
    description: <div style={{ color: type === 'error' ? 'red' : 'green' }}>{details.description}</div>,
    style: { padding: '.5rem', fontWeight: 'bold' }
  })
}
export default openNotificationWithIcon
