function MapIcon() {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='black' />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='#1C1C21' />
      <g clipPath='url(#clip0_1154_235)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.5077 30.5068L21 31L21.4923 30.5616C21.4983 30.5553 21.5068 30.5462 21.5179 30.5344C22.0764 29.9409 29 22.5831 29 18.1233C29 14.1781 25.4308 11 21 11C16.5692 11 13 14.1781 13 18.1233C13 22.6712 20.2 30.2329 20.5077 30.5068ZM14.2308 18.1233C14.2308 14.7808 17.2462 12.0959 21 12.0959C24.7539 12.0959 27.7692 14.7808 27.7692 18.1233C27.7692 21.6301 22.6 27.5479 21 29.3014C19.4 27.5479 14.2308 21.6301 14.2308 18.1233ZM18.4548 18.4995C18.4548 19.8995 19.5548 20.9995 20.9548 20.9995C22.3548 20.9995 23.4548 19.8995 23.4548 18.4995C23.4548 17.0995 22.3548 15.9995 20.9548 15.9995C19.5548 15.9995 18.4548 17.0995 18.4548 18.4995ZM19.4548 18.4995C19.4548 17.6495 20.1048 16.9995 20.9548 16.9995C21.8048 16.9995 22.4548 17.6495 22.4548 18.4995C22.4548 19.3495 21.8048 19.9995 20.9548 19.9995C20.1048 19.9995 19.4548 19.3495 19.4548 18.4995Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1154_235'>
          <rect width='16' height='20' fill='white' transform='translate(13 11)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MapIcon
