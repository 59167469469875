import classes from './Gallery.module.css'
import { Image } from 'antd'
import PropTypes from 'prop-types'
import generateSrcSetAndSizes from '../../utils/generateSrcSetAndSizes'

const Gallery = ({ media }) => {
  const images = media
  return (
    <>
      {images?.map((image, index) => {
        const { srcSet, sizes } = generateSrcSetAndSizes(image)
        return <Image rootClassName={classes.image} key={index} src={image.url} srcSet={srcSet} sizes={sizes} alt='project image' />
      })}
    </>
  )
}

export default Gallery

Gallery.propTypes = {
  media: PropTypes.array
}
