/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import classes from './Footer.module.css'
import FacebookIcon from '../Icon/Facebook'
import XIcon from '../Icon/X'
import LinkedInIcon from '../Icon/LinkedIn'
import InstagramIcon from '../Icon/Instagram'
import PhoneIcon from '../Icon/Phone'
import MapIcon from '../Icon/Map'
import MailIcon from '../Icon/Mail'
import normalizeUrl from '../../utils/normalizeUrl'

const Footer = ({ siteSetting }) => {
  const currentYear = new Date().getFullYear()
  const { siteLogo, siteName, officeNumber, officeAddress, officeEmail, facebook, linkedIn, instagram, twitter } = siteSetting || {}
  return (
    <>
      <section className={classes['container']}>
        <div className={classes['footer']}>
          <div className={classes['footer-logo-wrapper']}>
            <img className={classes['footer-logo']} src={siteLogo?.images?.[1].url || siteLogo?.url} alt='logo' />
          </div>
          <div className={classes['social-wrapper']}>
            <div className={`${classes['social']} highlight-text`}>Social</div>
            <div className={classes['social-icons']}>
              <a href={instagram ? normalizeUrl(instagram) : '#'} target='_blank' rel='noreferrer'>
                <InstagramIcon />
              </a>
              <a href={facebook ? normalizeUrl(facebook) : '#'} target='_blank' rel='noreferrer'>
                <FacebookIcon />
              </a>
              <a href={linkedIn ? normalizeUrl(linkedIn) : '#'} target='_blank' rel='noreferrer'>
                <LinkedInIcon />
              </a>
              <a href={twitter ? normalizeUrl(twitter) : '#'} target='_blank' rel='noreferrer'>
                <XIcon />
              </a>
            </div>
          </div>
          <div className={classes['contact-wrapper']}>
            <div className={`${classes['contact']} highlight-text`}>Contact Information</div>
            <div className={classes['phone-wrapper']}>
              <PhoneIcon />
              <a className='normal-text' href={`tel:${officeNumber}`}>
                {officeNumber}
              </a>
            </div>
            <div className={classes['address-wrapper']}>
              <MapIcon />
              <div className='normal-text'> {officeAddress}</div>
            </div>
            <div className={classes['email-wrapper']}>
              <MailIcon />
              <a className='normal-text' href={`mailto:${officeEmail}`}>
                {officeEmail}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className={classes['footer-legals-and-credits']}>
        <a className='small-text' href='/terms-and-conditions'>
          Terms and Conditions
        </a>
        <a className='small-text' href='/privacy-policy'>
          Privacy Policy
        </a>
        <div className='small-text'>© {currentYear} All Rights Reserved</div>
        <div className={`${classes['mervix']} small-text`}>
          Designed and Developed by&nbsp;
          <a className='small-text' href='https://revengebhatta.com'>
            Mervix Technologies
          </a>
        </div>
      </div>
    </>
  )
}

export default Footer

Footer.propTypes = {
  siteSetting: PropTypes.object
}
