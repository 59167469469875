import PropTypes from 'prop-types'
import classes from './TeamCard.module.css'
import FacebookIcon from '../Icon/Facebook'
import XIcon from '../Icon/X'
import InstagramIcon from '../Icon/Instagram'
import LinkedInIcon from '../Icon/LinkedIn'
import normalizeUrl from '../../utils/normalizeUrl'

const TeamCard = (member) => {
  const { name, designation, image, facebook, instagram, linkedin, twitter } = member || {}
  return (
    <>
      <div className={classes.card}>
        <div className={classes.profile}>
          <img src={image?.images?.[1].url || image?.url || ''} alt={name} />
        </div>
        <div className={classes.content}>
          <div className={`${classes.name} title`}>{name}</div>
          <div className={`${classes.designation} small-text `}>{designation}</div>
          <div className={classes.socials}>
            <a href={instagram ? normalizeUrl(instagram) : '#'} target='_blank' rel='noreferrer'>
              <InstagramIcon />
            </a>
            <a href={facebook ? normalizeUrl(facebook) : '#'} target='_blank' rel='noreferrer'>
              <FacebookIcon />
            </a>
            <a href={linkedin ? normalizeUrl(linkedin) : '#'} target='_blank' rel='noreferrer'>
              <LinkedInIcon />
            </a>
            <a href={twitter ? normalizeUrl(twitter) : '#'} target='_blank' rel='noreferrer'>
              <XIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamCard

TeamCard.propTypes = {
  member: PropTypes.object
}
