/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const getAllProject = createAsyncThunk('project/getAllProject', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/project')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all project!')
  }
})

export const postProject = createAsyncThunk('project/postProject', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/project', params.formData)
    params.resetForm()
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add project!')
  }
})
export const patchProject = createAsyncThunk('project/putProject', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.patch(`/admin/project/${params._id}`, params.formData)
    if (params.enableSwitch) {
      params.enableSwitch()
    } else {
      params.navigate()
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Update failed!')
  }
})

export const getOneProject = createAsyncThunk('project/getOneProject', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/project/${params.projectId}`)
    params.fillForm(response.data.data)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

export const deleteProject = createAsyncThunk('project/deleteProject', async (params, { rejectWithValue }) => {
  try {
    const response = axios.get(`/admin/project/${params._id}`)
    return (await response).data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    list: []
  },
  extraReducers: {
    [getAllProject.fulfilled]: (state, { payload }) => {
      state.list = payload
    },
    [getAllProject.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Cannot fetch projects!' })
    },
    [getAllProject.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postProject.fulfilled]: (state, action) => {
      state.list.push(action.payload)
      notification('success', { message: 'Success.', description: 'Project saved successfully.' })
    },
    [postProject.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Server error or validation error! ' })
    },
    [patchProject.pending]: (state) => {
      notification('info', { message: 'Working on it!.' })
    },
    [patchProject.fulfilled]: (state, action) => {
      notification('success', { message: 'Success.', description: 'Successfully updated.' })
    },
    [patchProject.rejected]: (state) => {
      // eslint-disable-next-line quotes
      notification('error', { message: 'Error!', description: "Project doesn't exist or validation error! " })
    },
    [deleteProject.pending]: (state, action) => {
      notification('info', { message: 'Working on it!.' })
    },
    [deleteProject.fulfilled]: (state, action) => {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex((item) => item._id === action.payload._id)
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1)
          })()
      notification('success', { message: 'Success.', description: 'Deleted successfully.' })
    },
    [deleteProject.rejected]: (state, action) => {
      // eslint-disable-next-line quotes
      notification('error', { message: 'Error!', description: "Project doesn't exist or server error! " })
    },
    [getOneProject.pending]: (state) => {
      notification('info', { message: 'Fetching project info!' })
    },
    [getOneProject.fulfilled]: (state) => {
      notification('success', { message: 'Successfully fetched.' })
    },
    [getOneProject.rejected]: (state) => {
      notification('error', { message: 'Server error!' })
    }
  }
})
export default projectSlice
