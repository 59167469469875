/* eslint-disable no-undef */
import { useState } from 'react'
import classes from './index.module.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import MobileNav from './MobileNav'
import PropTypes from 'prop-types'
import normalizeUrl from '../../utils/normalizeUrl'

const NavBar = ({ siteSetting, about }) => {
  const { siteLogo } = siteSetting || {}
  const [mobileNavIsVisible, setMobileNavIsVisible] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <nav className={classes['nav']} id='top'>
        {/* Logo */}
        <div className={classes['logo']} onClick={() => navigate('/')}>
          <div className={classes['icon']}>
            {siteLogo ? (
              <img
                src={(siteLogo !== null || undefined) && siteLogo !== undefined ? siteLogo.url : (() => {})()}
                alt='logo of the website'
              />
            ) : null}
          </div>
        </div>
        {/* Menu */}
        <div className={classes['links']}>
          <ul>
            <li>
              <NavLink className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])} to='/'>
                Home
              </NavLink>
            </li>
            {about?.portfolioLink && (
              <li>
                <NavLink
                  className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])}
                  to={normalizeUrl(about?.portfolioLink)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Portfolio
                </NavLink>
              </li>
            )}
            <li>
              <NavLink className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])} to='/about-me'>
                About Me
              </NavLink>
            </li>
            <li>
              <NavLink className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])} to='/services'>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])} to='/projects'>
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink className={(navData) => (navData.isActive ? classes['active'] : classes['passive'])} to='/contact'>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        {/* Mobile and Tablet Menu */}
        <div className={classes['menu']} onClick={() => setMobileNavIsVisible((prevState) => !prevState)}>
          <GiHamburgerMenu size={30} color='white' />
        </div>
        {mobileNavIsVisible && (
          <MobileNav navClickhandler={() => setMobileNavIsVisible((prevState) => !prevState)} siteLogo={siteLogo} about={about} />
        )}
      </nav>
    </>
  )
}

export default NavBar

NavBar.propTypes = {
  siteSetting: PropTypes.object,
  about: PropTypes.object
}
