import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getAbout = createAsyncThunk('about/getClientAbout', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/about')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all about!')
  }
})
const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    about: null
  },
  extraReducers: {
    [getAbout.fulfilled]: (state, { payload }) => {
      state.about = payload
    }
  }
})
export default aboutSlice
