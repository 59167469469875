import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getTermsAndConditions = createAsyncThunk(
  'termsAndConditions/getClientTermsAndConditions',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get('/client/termsandconditions')
      return response.data.data
    } catch (err) {
      return rejectWithValue('Failed to get all termsAndConditions!')
    }
  }
)

const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState: {
    termsAndConditions: {}
  },
  extraReducers: {
    [getTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.termsAndConditions = payload
    }
  }
})
export default termsAndConditionsSlice
