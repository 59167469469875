import React, { useState } from 'react'
import classes from './ContactForm.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { postContact } from '../../store/client/contactSlice'

const ContactForm = () => {
  const dispatch = useDispatch()
  const formStatus = useSelector((state) => state.clientContact.status)
  const [formData, setFormData] = useState({
    fullName: '',
    contactNumber: '',
    emailAddress: '',
    eventDate: '',
    message: '',
    services: {
      photography: false,
      videography: false,
      productionManagement: false
    }
  })

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        services: {
          ...formData.services,
          [name]: checked
        }
      })
    } else {
      setFormData({
        ...formData,
        [name]: value
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Extract selected services
    const selectedServices = Object.keys(formData.services).filter((service) => formData.services[service])

    // Prepare form data
    const params = new URLSearchParams()
    for (const key in formData) {
      if (key === 'services') {
        selectedServices.forEach((service) => {
          params.append('services[]', service)
        })
      } else if (typeof formData[key] === 'object') {
        for (const innerKey in formData[key]) {
          params.append(`${key}[${innerKey}]`, formData[key][innerKey])
        }
      } else {
        params.append(key, formData[key])
      }
    }

    dispatch(postContact({ formData: params, resetForm: resetForm }))
  }

  const resetForm = () => {
    setFormData({
      fullName: '',
      contactNumber: '',
      emailAddress: '',
      eventDate: '',
      message: '',
      services: {
        photography: false,
        videography: false,
        productionManagement: false
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} className={classes.contactForm}>
      <div className={classes.formGroup}>
        <label htmlFor='fullName'>Full Name</label>
        <input
          type='text'
          id='fullName'
          name='fullName'
          value={formData.fullName}
          onChange={handleChange}
          required
          className={classes.inputField}
        />
      </div>
      <div className={classes.formGroup}>
        <label htmlFor='contactNumber'>Contact Number</label>
        <input
          type='tel'
          pattern='\d*'
          id='contactNumber'
          name='contactNumber'
          value={formData.contactNumber}
          onChange={handleChange}
          required
          className={classes.inputField}
        />
      </div>
      <div className={classes.formGroup}>
        <label htmlFor='emailAddress'>Email Address</label>
        <input
          type='email'
          id='emailAddress'
          name='emailAddress'
          value={formData.emailAddress}
          onChange={handleChange}
          className={classes.inputField}
        />
      </div>
      <div className={classes.formGroup}>
        <label htmlFor='eventDate'>Event Date</label>
        <input
          type='date'
          id='eventDate'
          name='eventDate'
          value={formData.eventDate}
          onChange={handleChange}
          className={classes.inputField}
        />
      </div>
      <div className={`${classes.formGroup} ${classes.message}`}>
        <textarea
          id='message'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          className={classes.textArea}
          placeholder='Message'
        />
      </div>
      <div className={`${classes.formGroup} ${classes.services}`}>
        <label>Services</label>
        <div className={classes.checkboxContainer}>
          <div>
            <label>
              <input
                type='checkbox'
                name='photography'
                checked={formData.services.photography}
                onChange={handleChange}
                className={classes.checkbox}
              />
              Photography
            </label>
          </div>
          <div>
            <label>
              <input
                type='checkbox'
                name='videography'
                checked={formData.services.videography}
                onChange={handleChange}
                className={classes.checkbox}
              />
              Videography
            </label>
          </div>
          <div>
            <label>
              <input
                type='checkbox'
                name='productionManagement'
                checked={formData.services.productionManagement}
                onChange={handleChange}
                className={classes.checkbox}
              />
              Production Management
            </label>
          </div>
        </div>
      </div>
      <button type='submit' className={classes.submitButton}>
        Send Message
      </button>
      {formStatus === 'ACTIVE' && <div className={classes.successMessage}>Message Sent Successfully!</div>}
      {formStatus === 'REJECTED' && <div className={classes.errorMessage}>Failed to Send Message. Please try again later.</div>}
    </form>
  )
}

export default ContactForm
