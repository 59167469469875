import NavBar from '../../NavBar'
import Footer from '../../Footer'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSiteSetting } from '../../../store/client/siteSettingSlice'
import { getAbout } from '../../../store/client/aboutSlice'
function Layout({ children }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSiteSetting())
    dispatch(getAbout())
  }, [])
  const siteSetting = useSelector((state) => state.clientSiteSetting.siteSetting)
  const about = useSelector((state) => state.clientAbout.about)

  return (
    <>
      <NavBar siteSetting={siteSetting} about={about} />
      {children}
      <Footer siteSetting={siteSetting} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
