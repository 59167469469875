/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getBanner = createAsyncThunk('banner/getClientAllBanner', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/banner')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all banners!')
  }
})

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    status: 'PASSIVE',
    list: []
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.status = 'PENDING'
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.list = payload
    },
    [getBanner.rejected]: (state, { payload }) => {
      state.status = 'REJECTED'
    }
  }
})
export default bannerSlice
