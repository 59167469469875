import './App.css'
import SiteRouter from './routes'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { cmsAuthActions } from './store'
import { checkToken } from './store/cms/authSlice'

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkToken())
  }, [])

  return (
    <>
      <div className='App'>
        <SiteRouter />
      </div>
    </>
  )
}
export default App
