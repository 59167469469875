import PropTypes from 'prop-types'
import classes from './ServiceCard.module.css'
import CameraIcon from '../../assets/camera-icon.png'
import { useNavigate } from 'react-router-dom'
const ServiceCard = ({ serviceName, shortDescription, slug, className }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className={`${classes['card']} ${className ? className : ''}`} onClick={() => navigate(`/services/${slug}`)}>
        <div className={classes['card-title']}>
          <img src={CameraIcon} alt={serviceName} />
          <h3>{serviceName}</h3>
        </div>
        <div className={classes['card-content']}>
          <p>{shortDescription}</p>
        </div>
      </div>
    </>
  )
}

ServiceCard.propTypes = {
  serviceName: PropTypes.string,
  shortDescription: PropTypes.string,
  slug: PropTypes.string,
  className: PropTypes.string
}

export default ServiceCard
