import classes from './About.module.css'
import Testimonial from '../../../components/Testimonial'
import Top from '../../../components/Top'
import AboutMe from '../../../components/AboutMe'
import Team from '../../../components/Team'
import TalkProject from '../../../components/TalkProject'
import MilestoneCard from '../../../components/Card/Milestone'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAbout } from '../../../store/client/aboutSlice'
import { getAllTeam } from '../../../store/client/teamSlice'
import { getAllTestimonial } from '../../../store/client/testimonialSlice'
import Seo from '../../../utils/seo'

const About = () => {
  let milestone = [
    {
      number: '15+',
      description: 'Years in Business'
    },
    {
      number: '100+',
      description: 'Happy Clients'
    },
    {
      number: '90+',
      description: 'International Shoots'
    },
    {
      number: '100+',
      description: 'Photography Awards'
    },
    {
      number: '90%',
      description: 'Client Retention Rate'
    }
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAbout())
    dispatch(getAllTeam())
    dispatch(getAllTestimonial())
  }, [])
  const about = useSelector((state) => state.clientAbout.about)
  const teams = useSelector((state) => state.clientTeam.team)
  const testimonials = useSelector((state) => state.clientTestimonial.testimonial)
  return (
    <>
      <Seo
        title='Revenge Bhatta: About Me'
        description={
          about?.aboutMe ||
          'My journey as a photographer has been a lifelong quest to capture the extraordinary in the ordinary, to freeze fleeting moments in time, and to share the worlds beauty as I see it.'
        }
      />
      <Top className={classes.top} title='About Me' />
      <div className={classes.milestone}>
        {milestone.map((milestone, index) => (
          <MilestoneCard key={index} number={milestone.number} description={milestone.description} />
        ))}
      </div>
      <AboutMe about={about} />
      <Team team={teams} />
      <Testimonial testimonials={testimonials} />
      <TalkProject />
    </>
  )
}

export default About
