import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import CTA from '../../../components/GetStarted'
import Banner from '../../../components/Banner'
import AboutUsPreview from '../../../components/AboutUsPreview/index'
import Testimonial from '../../../components/Testimonial/index'
import ServicePreview from '../../../components/ServicePreview'
import TalkProject from '../../../components/TalkProject'
import FeaturedProjects from '../../../components/FeaturedProjects'
import { getAbout } from '../../../store/client/aboutSlice'
import { getAllTestimonial } from '../../../store/client/testimonialSlice'
import Seo from '../../../utils/seo'
const Landing = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAbout())
    dispatch(getAllTestimonial())
  }, [])
  const siteSettings = useSelector((state) => state.clientSiteSetting.siteSetting)
  const about = useSelector((state) => state.clientAbout.about)
  const testimonial = useSelector((state) => state.clientTestimonial.testimonial)
  return (
    <>
      <Seo
        title={`${siteSettings?.siteName || 'Revenge Bhatta'} - ${siteSettings?.organisationTagline || 'Photography & Videography'}`}
        description={about?.aboutMe}
        image={siteSettings?.siteLogo?.images?.[0].url || siteSettings?.siteLogo?.url}
      />
      <div
        style={{
          maxWidth: '1900px',
          margin: 'auto'
        }}
      >
        <CTA siteName={siteSettings.siteName} />
        <Banner />
        <AboutUsPreview about={about} />
        <FeaturedProjects />
        <Testimonial testimonials={testimonial} />
        <ServicePreview />
        <TalkProject />
      </div>
    </>
  )
}
export default Landing
