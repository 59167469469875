import Top from '../../../components/Top'
import classes from './Contact.module.css'
import ContactForm from '../../../components/form/ContactForm'
import Seo from '../../../utils/seo'
const Contact = () => {
  return (
    <>
      <Seo title='Revenge Bhatta: Contact Us' description='Contact us for inquiries or just to say hello!' />
      <Top title='Contact Us' />
      <section className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.title}>Get in touch</h2>
          <p className={`${classes.description} small-text`}>
            Whether you want to work with me or just say hello you&apos;re always welcome to drop me a line!
          </p>
        </div>
        <ContactForm className={classes.form} />
      </section>
    </>
  )
}

export default Contact
