import React, { Suspense } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import CmsPanel from '../pages/cms/CmsPanel'
import ClientRoutes from './clientRoutes/ClientRoutes'
import { useSelector } from 'react-redux'
const Login = React.lazy(() => import('../pages/cms/login/Login'))
const PageNotFound = React.lazy(() => import('../pages/404'))

const SiteRouter = () => {
  const user = useSelector((state) => state.cmsAuth.user)
  const location = useLocation()
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <Routes location={location} key={location.pathname}>
        <Route path='/*' element={<ClientRoutes />} />
        <Route exact path='admin/login' element={!user ? <Login /> : <Navigate to={'/admin'} />}></Route>
        {/* {!user && <Route exact path="admin/login" element={<Login />}></Route>} */}
        {
          user !== null && (
            <>
              <Route path='/admin/*' element={<CmsPanel />} />
            </>
          )
          // <Route path="/admin/*" element={<CmsPanel />} />
          // <Route path="*" element={<PageNotFound />}></Route>
        }
        <Route path='*' element={<PageNotFound />}></Route>
      </Routes>
    </Suspense>
  )
}
export default SiteRouter
