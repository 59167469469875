import classes from './RecentWorks.module.css'
import { useNavigate } from 'react-router-dom'
import { GoArrowUpRight } from 'react-icons/go'
import ProjectPreviewCard from '../Card/ProjectPreview'
import PropTypes from 'prop-types'

const RecentWorks = ({ recentProjects }) => {
  const navigate = useNavigate()

  const projectsToShow = recentProjects?.slice(0, 4)
  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.title}>Recent Works</h2>
          <div className={classes['link']}>
            <a className='small-text' target='' rel='noreferrer' onClick={() => navigate('/projects')}>
              View All Projects
              <GoArrowUpRight style={{ width: '20px', height: '20px' }} />
            </a>
          </div>
        </div>
        <div className={classes['projects']}>
          <div className={classes['flex-container']}>
            {projectsToShow.map((item, index) => (
              <ProjectPreviewCard
                className='project-card'
                key={index}
                projectName={item?.title}
                media={item?.featuredMediaType?.toLowerCase() === 'image' ? item.featuredImage?.images?.[1] : item?.youTubeLink}
                projectDate={item?.projectYear}
                slug={item?.slug}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default RecentWorks

RecentWorks.propTypes = {
  recentProjects: PropTypes.array
}
