import React from 'react'

function XIcon() {
  return (
    <svg className='social-media-icon' width='40' height='40' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='#1C1C21' />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='url(#paint0_linear_1104_512)' />
      <g clipPath='url(#clip0_1104_512)'>
        <path
          d='M18.0334 27.5C24.0697 27.5 27.3722 22.4978 27.3722 18.1612C27.3722 18.0206 27.3691 17.8769 27.3628 17.7362C28.0052 17.2716 28.5597 16.6962 29 16.0369C28.4017 16.3031 27.7664 16.4769 27.1159 16.5525C27.8009 16.1419 28.3137 15.497 28.5594 14.7372C27.915 15.119 27.2104 15.3884 26.4756 15.5337C25.9806 15.0077 25.326 14.6594 24.6131 14.5427C23.9003 14.426 23.1688 14.5474 22.5318 14.8881C21.8949 15.2288 21.3879 15.7698 21.0892 16.4275C20.7906 17.0853 20.717 17.8231 20.8797 18.5269C19.575 18.4614 18.2986 18.1225 17.1333 17.5321C15.968 16.9417 14.9398 16.113 14.1153 15.0997C13.6963 15.8222 13.568 16.6771 13.7567 17.4907C13.9453 18.3044 14.4367 19.0156 15.1309 19.48C14.6098 19.4634 14.1 19.3231 13.6438 19.0706V19.1112C13.6433 19.8694 13.9054 20.6044 14.3855 21.1912C14.8657 21.778 15.5342 22.1804 16.2775 22.33C15.7947 22.4621 15.288 22.4813 14.7966 22.3862C15.0063 23.0383 15.4144 23.6086 15.9638 24.0176C16.5133 24.4265 17.1767 24.6537 17.8616 24.6675C16.6989 25.5807 15.2628 26.0761 13.7844 26.0737C13.5222 26.0733 13.2603 26.0573 13 26.0256C14.5019 26.9892 16.249 27.5009 18.0334 27.5Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_1104_512' x1='21' y1='0.999995' x2='21' y2='41' gradientUnits='userSpaceOnUse'>
          <stop offset='0.278527' stopColor='#2F2F37' />
          <stop offset='1' stopColor='#2F2F37' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_1104_512'>
          <rect width='16' height='16' fill='white' transform='translate(13 13)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default XIcon
