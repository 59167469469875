/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'
import notification from '../../components/notification'

export const getPrivacyPolicy = createAsyncThunk('privacyPolicy/getPrivacyPolicy', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/admin/privacypolicy')
    if (response.data.data) {
      params.fillForm(response.data.data)
    }
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to fetch privacy policy!')
  }
})

export const postPrivacyPolicy = createAsyncThunk('privacyPolicy/postPrivacyPolicy', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post('/admin/privacypolicy', params.formData)
    return await response.data.data
  } catch (err) {
    return rejectWithValue('Failed to add privacy policy!')
  }
})

const webprivacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState: {
    privacyPolicy: null
  },
  extraReducers: {
    [getPrivacyPolicy.fulfilled]: (state, { payload }) => {
      state.privacyPolicy = payload
    },
    [getPrivacyPolicy.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Cannot fetch privacy policy!' })
    },
    [postPrivacyPolicy.pending]: (state) => {
      notification('info', { message: 'Working on it!' })
    },
    [postPrivacyPolicy.fulfilled]: (state, action) => {
      state.privacyPolicy = action.payload
      notification('success', { message: 'Success.', description: 'privacy policy saved successfully.' })
    },
    [postPrivacyPolicy.rejected]: (state) => {
      notification('error', { message: 'Error!', description: 'Server error or validation error! ' })
    }
  }
})
export default webprivacyPolicySlice
