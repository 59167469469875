import classes from './Top.module.css'
import PropTypes from 'prop-types'
const Top = ({ title }) => {
  return (
    <div className={classes.top}>
      <h1 className={classes.cover}>{title ? title : 'Your title here'}</h1>
    </div>
  )
}
export default Top

Top.propTypes = {
  title: PropTypes.string
}
