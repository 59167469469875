import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../buttons/PrimaryButton'
import classes from './TalkProject.module.css'

const TalkProject = () => {
  const navigate = useNavigate()
  return (
    <>
      <section className={classes['container']}>
        <div className={classes['header-wrapper']}>
          <h1>Lets Talk About Your Project</h1>
          <PrimaryButton onClick={() => navigate('/contact')}>Book Now</PrimaryButton>
        </div>
      </section>
    </>
  )
}

export default TalkProject
