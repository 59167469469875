/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getSiteSetting = createAsyncThunk('siteSetting/getClientSiteSetting', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/sitesetting')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all siteSetting!')
  }
})

const siteSettingSlice = createSlice({
  name: 'siteSetting',
  initialState: {
    siteSetting: {}
  },
  extraReducers: {
    [getSiteSetting.fulfilled]: (state, { payload }) => {
      state.siteSetting = payload
    }
  }
})
export default siteSettingSlice
