import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios'

export const getAllService = createAsyncThunk('service/getClientAllService', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get('/client/service')
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed to get all service!')
  }
})

export const getOneService = createAsyncThunk('service/getClientOneService', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/client/service/${params.slug}`)
    return response.data.data
  } catch (err) {
    return rejectWithValue('Failed!')
  }
})
const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    status: 'PASSIVE',
    list: [],
    selectedService: null
  },
  extraReducers: {
    [getAllService.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.list = payload
    },
    [getAllService.rejected]: (state) => {
      state.status = 'REJECTED'
    },
    [getAllService.pending]: (state) => {
      state.status = 'PENDING'
    },
    [getOneService.pending]: (state) => {
      state.status = 'PENDING'
    },
    [getOneService.fulfilled]: (state, { payload }) => {
      state.status = 'ACTIVE'
      state.selectedService = payload
    },
    [getOneService.rejected]: (state) => {
      state.status = 'REJECTED'
    }
  }
})
export default serviceSlice
